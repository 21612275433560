//main: pita.scss

body {

	.view-eventi { // NUOVA GESTIONE ASSENZA IMMAGINE,

		&.image-no-space { // classe statica messa nella vista

			.view-content {

				display:flex;
				flex-wrap: wrap;

				> div {

					&.single-item {

						margin-bottom:$padding-default;

						&.has_no_field_image {

							> div {

								@extend %style-no-image-card-group;
								height: auto!important;
								display: inline-table;
								width: 100%;

							}

						}

						> div {

							@extend %style-card-no-image;

						} //div

						.views-field-field-image {

							@extend %style-no-image-card;

						}

					} //single-item

				}

			}

		}

		.view-content {

			> div { // sovracsrivo qualche  stile definito x tutte le card

				&.single-item { 

					> div {	

						@extend %style-card-eventi;

					} //div

				} //single-item

			}

		} //view-content

	}

	.view-enoteche,
	.view-natura,
	.view-tempo-libero,
	.view-enoturismo,
	.view-esperienze,
	.view-in-evidenza,
	.view-eventi,
	.view-dormire,
	.view-musei,
	.view-golf,
	.view-impianti-sportivi,
	.view-comprensori,
	.view-attivita,
	.view-ristoranti,
	.view-shopping,
	.view-ricette,
	.view-curiosita	{ // ALL VIEW

		.view-header { // intestazione risultati ricerca

			background-color:$verde;
			padding:1rem;
			overflow:hidden;
			@extend %middle-xs;

			h2 {

				padding:0;
				margin:0;
				text-transform:uppercase;
				@include tipografia( "base", $font-size-h2-search, $nero, $font-weight-light);
				@extend .col-xs-8;

			}

			.filtro {

				text-align: right;
				width: 100%;
			    position: relative;
				height: 25px;
			}

			a {

				margin:0;
				padding:0;
				@extend .col-xs-4;
				float: right;
				line-height: 1rem;
				font-size: 1.2rem;


				&.icon {

					line-height: 1rem;
					font-size: 1.2rem;
					color:$nero;
					width: auto;
					position: absolute;
					right: 0;
					padding: 0;


					&:before {

						color:$nero;
						font-size: 1.1rem;
						margin-right: 0.4rem;

					}

					&:hover,
					&:focus {

						border-bottom: 1px solid $nero;
						text-decoration:none;

						// &:before {
						//
						// 	//color: $colorBk-azzurro;
						//
						// }

					}

					span {

						@include sr-only;

					}

				}

			} //a


		}

		.view-empty {

			margin-top:$padding-default;
			border:2px solid $rosso;
			text-align:center;

			p {margin:1rem;}

		}

		.view-content {

			margin-top:$padding-default;
			@extend .row;
			display:flex;
			flex-wrap: wrap;

			.single-item {

				// start GENERATE COLOUR NTH-CHILD
				@for $i from 1 through length($colorsGrad) {
					&:nth-child(#{length($colorsGrad)}n+#{$i}) {

						div {

							.views-field-field-image {

								background-color: nth($colorsGrad, $i);

							}

						}

					}
				}
				// end GENERATE COLOUR NTH-CHILD

			}

			> div { // direttamente qui i campi se vista Campi

				&.single-item { // aggiunto nella vista di tipo campo su elenco non formattato

					margin-bottom:$padding-default;

					> div {	// aggiunto nel template views-view-unformatted.tpl.php

						@extend %style-card;

					} //div


					.views-field-field-image {

						@extend %style-image-card;

					}

					.views-field {

						@extend %space-element-card;

					}

					.views-field-title {

						@extend %style-title-card;

					}

					// .views-field-field-indirizzo-administrative-area {

					// 	p:first-letter {
					// 		text-transform: uppercase;
					// 	}

					// 	.citta {

					// 		text-transform: lowercase;

					// 	}

					// }

					.views-field-view-node {

						padding:0!important;

						a {

							position:absolute;
							top:0;
							left:0;
							width:100%;
							height:100%;
							text-indent:-10000000px;
							overflow:hidden;

						}

					}

				} //single-item

				.node {	 // vista come anteprima

					&.node-teaser {

						.bkg-teaser {

							//min-height: 8rem;
							background-size: cover;
							background-position: center center;

						}

						&.has-image {
							// background-image:none;
							padding-top:0!important;
							padding-bottom:0!important;
						}

					}

					// messa anche su vista campi

					position:relative;
					margin-bottom:$padding-default;

					// QUESTA DEFINIZIONE E' PRINCIPALE PER 'IN EVIDENZA' ...allargata in evidenza 2024

					min-height: 10rem;

					@media (min-width: $grid-float-breakpoint) {	//$screen-sm-min : 768px

						min-height: 15.4rem;

					}

					@include background(texture_trasparente,png,repeat,center,top,auto, auto);
					@include alpha-attribute('background-color', rgba($grigio-scuro, 0.3), $bianco);

					&:hover, &:focus {
						@include alpha-attribute('background-color', rgba($nero, 0.3), $bianco);
					}

					// FINE messa anche su vista campi

					@extend %link-readmore;

					h3, h2 {

						@extend %transition-classic;
						font-size:$font-size-h4;
						padding:$padding-preview-element;
						margin:0;

						@media (min-width: $grid-float-breakpoint) {	//$screen-sm-min : 768px
							min-height:4rem;
						}

					}

					.content {

						.field-name-field-data {

							@extend %transition-classic;
							@extend %data-anteprima;

						}

						.field-name-field-image {


							@extend %fit-image;
							@extend %caption-image;


						}

					}

					&:focus,
					&:hover {

						.content {

							.field-name-field-data {

								background-color:$nero;
								color:$bianco;

							}

						}

					}

				} //node

			}

		} //view-content

		@media (max-width: $grid-float-breakpoint-max) { //$screen-sm-max -1  : 767px

			// .view-content {

				// > div {

					// &:last-child {

						// .node {

							// margin-bottom:0;

						// } //node

					// }

				// } //div

			// } //view-content

		} //media

	} //views all

	.view-enoteche,
	.view-natura,
	.view-tempo-libero,
	.view-enoturismo,
	.view-esperienze,
	.view-eventi,
	.view-dormire,
	.view-musei,
	.view-golf,
	.view-impianti-sportivi,
	.view-comprensori,
	.view-attivita,
	.view-ristoranti,
	.view-shopping,
	.view-ricette,
	.view-curiosita	{

		.view-content {

			> div {

				@include cols(12, 6, 4);

			}

		}

	}

	.view-esperienze {

		.view-content {

			margin-top:0;

			> div {

				.node {

					@extend %style-titolo-card-esperienze;

				}

			}

		}

	}

	.view-natura,
	.view-tempo-libero,
	.view-enoturismo,
	.view-in-evidenza,
	.view-dormire,
	.view-musei,
	.view-golf,
	.view-impianti-sportivi,
	.view-comprensori,
	.view-attivita,
	.view-ristoranti,
	.view-shopping,
	.view-ricette,
	.view-curiosita	{

		.view-content {

			> div {

				.node {

					border-top:6px solid $blu-bordo;
					background-color:$blu-puffo;
					// margin-bottom: 15px;

						// @media (min-width: $grid-float-breakpoint) {	//$screen-sm-min : 768px

							// margin-bottom: 30px;

						// } //media


					h3, h2 {

						padding-top:$padding-default;
						padding-bottom:$padding-default;
						background-color:$blu-puffo;

					}

					&:hover,
					&:focus {

						border-top:6px solid $arancione;
						background-color:$blu-bordo;
						color:$bianco;

						h3, h2 {

							background-color:$blu-bordo;

						}

					}

				}

			}

		}

	}//view


	&.not-front {

		.view-in-evidenza {

			.view-content {

				.has_no_field_image {

					@include cols(12, 6, 4);
					margin-bottom:2rem;

					> div {

						height: 100%;
			
						.node {
		
							@extend .d-flex;
							@extend .flex-direction-column;
		
							height: 100%;
							margin-bottom: 50px;
							background-image: none;
		
							span.rdf-meta { // rdf-meta
								// order: 3;
							}
		
							.content {
								// order: 1;
								margin-top:auto;
								@media (max-width: $screen-sm-min) { 
									min-height:255px;
								}

								@extend %style-image-card;

							}
		
							.links {
								// order: 4;
							}
		
							h3 {
		
								background-color:transparent;
								// order: 2;
								@extend %style-title-card;
		
							}
		
							&:hover,
							&:focus {
		
								h3 {
		
									background-color:transparent;
		
								}
		
							}
		
						}	//node
		
					}

				}

			}

		}//view

	}


	.view-eventi { // x homepage vedere .section-eventi

		.view-content {

			> div {

				h3 {background-color:$bianco}

				.node {

					&.node-teaser {

						@media (min-width: $grid-float-breakpoint) {	//$screen-sm-min : 768px

							padding-top:194px;

						} // $grid-float-breakpoint

						@media (min-width: $screen-md-min) { //992px

							padding-top:165px;

						} // $screen-md-min

						@media (min-width: $screen-lg-min) { //1200px

							padding-top:203px;

						} // $screen-lg-min

						@media (min-width: $screen-xl-min) { //1400px

							padding-top:259px;

						} // $screen-xl-min

					}

				} //node


				&.single-item { // aggiunto nella vista di tipo campo su elenco non formattato

					> div {

						border-top:0;
						background-color:$bianco;

						&:hover,
						&:focus {

							border-top:0;

						}

					}

					.views-field-field-image {

						@include alpha-attribute('background-color', rgba($grigio-scuro, 0.3), $bianco);

					}

					.views-field-field-data {

						@extend %data-anteprima;

					}

				}

			}

		}

	}

}
