
// definisco FLEX da boostrap 4
// https://getbootstrap.com/docs/4.0/utilities/flex/
// @include xxxx

@mixin d-flex {
  display: -webkit-box!important;
  display: -ms-flexbox!important;
  display: flex!important;
}

@mixin flex-wrap {
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap!important;
}

@mixin flex-row-reverse {
  // -webkit-box-orient: horizontal!important;
  // -webkit-box-direction: reverse!important;
  -ms-flex-direction: row-reverse!important;
  flex-direction: row-reverse!important;
}

@mixin flex-column-reverse {
  -ms-flex-direction: column-reverse!important;
  flex-direction: column-reverse!important;
}

@mixin flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse!important;
  flex-wrap: wrap-reverse!important;
}

@mixin align-items-stretch { // su flex
  -webkit-box-align: stretch!important;
  -ms-flex-align: stretch!important;
  align-items: stretch!important;
}

@mixin align-items-center { // su flex
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
}

@mixin align-self-stretch { // su item in to flex
  -ms-flex-item-align: stretch!important;
  align-self: stretch!important;
}

@mixin align-self-center { // su item in to flex
  -ms-flex-item-align: center!important;
  align-self: center!important;
}
