//main: pita.scss


// DEFINITION ONLY FRONTPAGE

.front {

  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;

  @media (max-width: $grid-float-breakpoint-max) {

    background-position: center 115px;

  }

  section {

    margin-top: 40%;
    padding-bottom: $space-bottom-page;

  }

  div[class^="section-"] {

    // padding-top:$padding-default-half;
    // padding-bottom:$padding-default-half;

    &.section-eventi {

      padding: 0;
      background-color: $grigio-scuro;
      color: $bianco;

      .more-link {

        a {

          border-color: $bianco;
          color: $bianco;

          &:hover {

            color: $bianco;
            border-color: $arancione;

          }

        }

      }

    }

    &.section-link {

      padding-top: 0;
      padding-bottom: 0;

    }

  }

  //all-section

  .section-esperienze,
  .section-inevidenza {

    h2 {

      @extend %sr-only;

    }

  }

  .section-link {

    margin-top: $space-bottom-page;
    margin-bottom: $space-bottom-page;

    @media (min-width: $screen-md-min) {
      //992px

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#71ca11+0,71ca11+50,3e4f5c+50,3e4f5c+100 */
      background: #71ca11;
      /* Old browsers */
      // background: -moz-linear-gradient(-45deg,  #71ca11 0%, #71ca11 50%, #3e4f5c 50%, #3e4f5c 100%); /* FF3.6-15 */
      // background: -webkit-linear-gradient(-45deg,  #71ca11 0%,#71ca11 50%,#3e4f5c 50%,#3e4f5c 100%); /* Chrome10-25,Safari5.1-6 */
      // background: linear-gradient(135deg,  #71ca11 0%,#71ca11 50%,#3e4f5c 50%,#3e4f5c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#71ca11', endColorstr='#3e4f5c',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    }


    .region-section-3home {

      @media (min-width: $screen-md-min) {
        //992px

        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: flex;

      }

    }

    .link-language,
    .link-social {

      padding: 1rem 0;
      min-height: 2px;

    }

    .link-language {

      @media (max-width: $screen-sm-max) {

        background-color: $grigio-scuro;

      }

      ul {

        @extend .list-group;
        margin: 0;

        li {

          @extend .list-group-item;
          text-align: center;
          border: 0;
          border-radius: 0;
          background-color: transparent;
          padding: 0;

          a {

            @extend %link-bianco;
            font-size: 2rem;

            &:hover,
            &:focus {

              color: $arancione;

            }

          }

        }

      }

    }

    .link-social {

      padding: 1rem 0;

      @media (max-width: $screen-sm-max) {

        background-color: $verde;

      }

      @media (min-width: $screen-xs-min) {
        //480px

        font-size: 1.5rem;

      }

      // $screen-xs-min


      .content {

        text-align: center;

        ul {

          @extend .list-inline;
          margin-bottom: 0;

          li {

            width: 20%;

            @media (min-width: $screen-xs-min) {
              //480px

              width: 15%;

            }

            // $screen-xs-min

            span {

              @extend .sr-only;

            }

            a {

              @extend %link-nero;
              @extend %link-no-underline;
              @extend .fa;
              font-size: 3rem;

            }

            &:nth-of-type(1) {

              a {

                width: 40%;
                display: inline-block;

                @extend .fa-facebook-square;

                &:hover,
                &:focus {

                  @extend .fa-facebook;

                }

              }

            }

            &:nth-of-type(2) {

              a {

                @extend .fa-twitter-square;

                &:hover,
                &:focus {

                  @extend .fa-twitter;

                }

              }

            }

            &:nth-of-type(3) {

              a {

                @extend .fa-instagram;

                &:hover,
                &:focus {

                  color: $verde;
                  background-color: $nero;
                  border-radius: 20px;
                  padding: 0 4px;

                  @media (max-width: $grid-float-breakpoint-max) {
                    //$screen-sm-max -1  : 767px

                    border-radius: 10px;

                  }

                  // $grid-float-breakpoint-max

                }

              }

            }

            &:nth-of-type(4) {

              a {

                @extend .fa-youtube-square;

                &:hover,
                &:focus {

                  @extend .fa-youtube;

                }

              }

            }

          }

        }

        a {

          color: $nero;

          &:hover,
          &:focus {

            color: $nero;

          }

        }

        div {
          font-size: $fz-big
        }

      }

    }

    // border-top: 1px solid #ccc;
    // border-bottom: 1px solid #ccc;

  }


  // la vista curiosità ha ordine diverso degli elementi

  .section-esperienze {

    .block-anteprima-esperienze {

      .view-esperienze {

        .node-curiosita {

          &.node-teaser {

            display: flex;
            flex-direction: column;

            .content {

              order: 2;

            }

            h3 {

              order: 1;

            }

          }

        }

      }

    }

  }



  .view-in-evidenza {

    .view-content {

      >div {

        @include cols(12, 6, 6);

        .node {

          background-image: none;
          // a {

          // &.full-link {

          border-top: 6px solid $arancione;
          @include alpha-attribute('background-color', rgba($bianco, 0.8), $bianco);

          h3,
          h2 {

            background-color: transparent;

          }

          &:hover,
          &:focus {

            @include alpha-attribute('background-color', rgba($blu-bordo, 0.8), $blu-bordo);

            h3,
            h2 {

              background-color: transparent;

            }


          }


          @media (min-width: $grid-float-breakpoint) {
            //$screen-sm-min : 768px

            min-height: 200px;


          }

          //media

          // } //full-link

          // }

        }

        //node

      }
    }

  }

  //view-in-evidenza

  .block-anteprima-eventi {

    background-color: transparent;

    .view-eventi {

      .view-content {

        >div {

          &.single-item {

            &.no_image {

              &:last-child {

                @include cols(12, 12, 4);

              }

              @include cols(12, 6, 4);

              >div {

                @extend %style-no-image-card-homepage-eventi;

                border: 1px solid #acb3b9;

                &:hover {

                  border: 1px solid $bianco;

                }

                .views-field {

                  text-align: center;

                  &.views-field-field-image {

                    display: none;

                  }

                }

              }

            }

            @include cols(12, 6, 6);

            >div {

              background-color: $grigio-scuro;
              color: $bianco;
              border: 1px solid $grigio-scuro;

              &:hover {

                background-color: $grigio-scuro;
                color: $bianco;
                border: 1px solid $bianco;

              }

              .views-field {

                text-align: center;

                &.views-field-field-data {

                  background-color: $grigio-scuro;
                  color: $bianco;
                  font-size: $font-size-h6;
                  font-style: normal;
                  font-weight: $font-weight-light;

                }

                &.views-field-title {

                  padding-top: 0;

                }

              }

            }

          }

        }

        //div

      }

      //view-content

      .view-footer {

        .more-link {

          a {

            border-color: $bianco;
            color: $bianco;

            &:hover {

              color: $bianco;
              border-color: $arancione;

            }

          }

        }

      }

    }

  }



}

//front
