//main: pita.scss

// COMPRENSORI SCIISTICI (cominciamo a dividere) - PITA


body {

	.page-content {

		&.page-nodo {

			.group-info-only-1 {

				.info-1 {

					.region-nodo-info1 {

						@extend .row;

						#block-views-impianti-icona-block,
						#block-views-piste-grado-block,
						.field-luoghi {

							@extend .col-md-4;
							width:auto!important;

						}

						#block-fieldblock-node-impianti-risalita-default-body,
						.field-noleggio-attrezzature,
						.field-snowpark  {

							@extend .col-md-4;
							width:100%!important;

						}

					}

				}

			}

		} //page-nodo

	}

} // body
