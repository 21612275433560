//main: pita.scss

body {

	#ui-datepicker-div {

		font-size: $fz-picker;
		font-family: $font-family-base;
		background: $blu-puffo;
		border:1px solid $grigio-scuro;
		z-index: 9999;
		border-radius: 0;

	}

	/* Datepicker
	----------------------------------*/
	.ui-datepicker  {

		.ui-widget-header {

			background: $blu-puffo;
			border: 0;

		}

		.ui-datepicker-title {

			select {

				font-size: $fz-picker;
				padding: 0.2em;
				height:auto;

			}

		}

		.ui-datepicker-calendar {

			tbody {

				border-top: 0;

				td {

					&.ui-datepicker-current-day {

						a {

							&.ui-state-default {

								background-color: $verde;
								color: $nero;
								font-weight: bold;

							}

							&.ui-state-hover,
							&.ui-state-focus {

								background-color: transparent;
								background-image: none;
								border: 1px solid $grigio-scuro;

							}

						}

					}

					&.ui-datepicker-today {

						a {

							&.ui-state-default {

								background-color: $bianco;
								background-image: none;
								color: $grigio-scuro;
								border: 1px solid $bianco;

							}

							&.ui-state-hover,
							&.ui-state-focus {

								background-color: transparent;
								background-image: none;
								color: $grigio-scuro;

							}

						}

					}

				}

			}

			a {

				&.ui-state-default {

					background-color: $grigio-scuro;
					background-image: none;
					color: $bianco;
					border: 1px solid $grigio-scuro;

				}

				&.ui-state-hover,
				&.ui-state-focus {

					background-color: transparent;
					background-image: none;
					color: $grigio-scuro;

				}

			}

		}

		.ui-datepicker-next,
		.ui-datepicker-prev {

			border-radius: 0;
			border:0;
			cursor: pointer;

			&.ui-state-hover,
			&.ui-state-focus {

				background-color: transparent;
				border:0;
				background-image: none;

			}

		}


	}


}
