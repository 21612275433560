//main: pita.scss

//colors

$white: white;
$color_calendaractive:#e35a30;
// $color_calendarWE:#FFAE90;
$color_calendarsun: #fb764e; // #80E218; //#FEA791; //#FBB07E;
$color_calendarsat: $color_calendarsun;

$color-bk-calendar: #3e4e5b;

$color_silver_approx: #bcbcbc;
$color_shark_approx: #1b252c;

%extend_1 {
	color: $white;
	font-size: 40px;
	background: none!important;
	padding-right: 5px;
}


//@extend-elements
//original selectors
//.calendar-calendar .view-header .date-nex a, .calendar-calendar .view-header .date-pre a

// ex view-id-calendario

body .calendario-home{

	background-color:$color-bk-calendar;
	@include tipografia;

	.view-header{

		background-color: transparent;
		padding:0;
	}

	.view-content{

		text-align: center;
	}

	.view-header {

		font-size: 40px;
		margin: 0 15px;

		.date-head {

			color: $white;
			font-size: 1.1rem;
			padding-left:0rem!important;
			display: inline-block;
			width:9rem;
			/*line-height: 40px;*/
			/*text-transform: uppercase;*/
			vertical-align: middle;
		}

		a {

			color: $white;
			font-weight: 300px;
			border: none;
			padding: 0;
			border:0!important;
			background-color: transparent;
			float:none;

			&:hover {

				text-decoration: none;
				border-bottom: none;
			}
		}

		.date-nex a {

			@extend %extend_1;
		}

		.date-pre a {

			@extend %extend_1;
			padding-right:0;
		}

		.item-list ul.pager {

			text-align: center;
			margin: 8px 0;

			li {

				border: none;
				padding: 0;
				vertical-align: middle;

				a{

					border:0!important;
					background-color: transparent;
				}
			}
		}
	}

	.view-content div.calendar-calendar{

		@extend .col-md-12;
		//margin: 0 -15px;



	}

	.date-nav-wrapper {
		display: block!important;
		margin: 0;
		.date-nav {
			margin: 0;
			padding-bottom: 0;
		}
	}
	.view-content {
		padding-top: 0;
		margin-top:0;
	}
	.mini {

		width:100%;

		ul {

			margin: 0 15px;
			padding: 0;
			padding-top: 13px;


			li {

				padding: 12px 0 0 0;
				display: inline-block;
				margin:0 -5px 0;
				position: relative;
				width:1.8rem;
				border-top:1px solid $white;

				&:after{

					content: "|";
					position: absolute;
					top:-2px;
					left:50%;
					font-size:13px;
					color:$white;
				}
				a {
					color: $white;
					font-weight: bold;
					z-index: 1000;
					font-size: 0.9rem;
					padding:3px 2px;

					&:hover {

						text-decoration: none;
					}
				}
				div {
					display: inline-block;
					width:100%;
					text-align: center;
				}
				&.empty {
					display: none;
				}
				&.sat a {
					color: $color_calendarsat;
				}
				&.sun a {
					color: $color_calendarsun;
				}
				&.today a {
					background-color: $color_calendaractive;
				}
			}
		}
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 320px) and (max-width: 1024.98px) {
	body .calendario-home .view-content{
		margin: 0 15px;
	}
	body .calendario-home .mini ul{
		width:11rem;
		margin:0 auto;
		li{
			border-top:0;
			&:after{

				content: " ";
			}
		}
	}

 }

/*
}*/
@media(min-width: 992px) {
}
	/*
	.calendar-calendar {
		background-color: $color_shark_approx;
		margin: 0 -15px;
		font-family: $font_0;
		.view-header {
			font-size: 40px;
			margin: 0 15px;
			.date-head {
				color: $white;
				font-size: 40px;
				line-height: 50px;
				text-transform: uppercase;
				vertical-align: 0;
			}
			a {
				color: $white;
				font-weight: 300px;
				border: none;
				padding: 0;
				&:hover {
					text-decoration: none;
					border-bottom: none;
				}
			}
			.date-nex a {
				color: $white;
				font-size: 40px;
				background: none!important;
				padding-right: 5px;
			}
			.date-pre a {
				color: $white;
				font-size: 40px;
				background: none!important;
				padding-right: 5px;
			}
			.item-list ul.pager {
				text-align: left;
				margin: 0;
				li {
					border: none;
				}
			}
		}
		.date-nav-wrapper {
			display: block!important;
			margin: 0;
			.date-nav {
				min-height: 10px;
				margin: 0;
				padding-bottom: 0;
			}
		}
		.view-content {
			padding-top: 0;
		}
		.mini {
			width: 100%;
			ul {
				margin: 0 15px;
				padding: 0;
				padding: 8px 0;
				li {
					padding: 0;
					display: inline;
					position: relative;
					a {
						color: $white;
						font-weight: bold;
						background-color: $color_shark_approx;
						z-index: 1000;
					}
					div {
						display: inline;
					}
					&.empty {
						display: none;
					}
					&.sat a {
						color: $color_alizarin_crimson_approx;
					}
					&.sun a {
						color: $color_alizarin_crimson_approx;
					}
					&.today a {
						background-color: $color_slate_gray_approx;
					}
				}
			}
		}
	}
}
@media(min-width: 992px) {
	.calendar-calendar {
		background-color: $color_shark_approx;
		margin: 0 -15px;
		font-family: $font_0;
		.view-header {
			font-size: 40px;
			margin: 0 15px;
			.date-head {
				color: $white;
				font-size: 40px;
				line-height: 50px;
				text-transform: uppercase;
				vertical-align: 0;
			}
			a {
				color: $white;
				font-weight: 300px;
				border: none;
				padding: 0;
				&:hover {
					text-decoration: none;
					border-bottom: none;
				}
			}
			.date-nex a {
				color: $white;
				font-size: 40px;
				background: none!important;
				padding-right: 5px;
			}
			.date-pre a {
				color: $white;
				font-size: 40px;
				background: none!important;
				padding-right: 5px;
			}
			.item-list ul.pager {
				text-align: left;
				margin: 0;
				li {
					border: none;
				}
			}
		}
		.date-nav-wrapper {
			display: block!important;
			margin: 0;
			.date-nav {
				min-height: 10px;
				margin: 0;
				padding-bottom: 0;
			}
		}
		.view-content {
			padding-top: 0;
		}
		.mini {
			width: 100%;
			ul {
				margin: 0 15px;
				padding: 0;
				padding: 8px 0;
				li {
					padding: 0;
					display: inline;
					position: relative;
					a {
						color: $white;
						font-weight: bold;
						background-color: $color_shark_approx;
						z-index: 1000;
						font-size: 21px;
					}
					div {
						display: inline;
					}
					&.empty {
						display: none;
					}
					&.sat a {
						color: $color_alizarin_crimson_approx;
					}
					&.sun a {
						color: $color_alizarin_crimson_approx;
					}
					&.today a {
						background-color: $color_slate_gray_approx;
					}
				}
			}
		}
	}
}
@media(min-width: 1200px) {
	.calendar-calendar {
		background-color: $color_shark_approx;
		margin: 0 -15px;
		font-family: $font_0;
		.view-header {
			min-height: 90px;
			height: auto!important;
			height: 90px;
			font-size: 60px;
			margin: 0 15px;
			.date-head {
				color: $white;
				font-size: 40px;
				line-height: 60px;
				text-transform: uppercase;
				vertical-align: 5px;
			}
			.item-list ul.pager {
				margin: 0;
				text-align: left;
				li {
					border: none;
					padding: 0;
					a {
						color: $white;
						background: none !important;
						font-size: 60px;
						&:hover {
							color: $white;
							text-decoration: none;
						}
					}
				}
			}
		}
		a {
			color: $white;
			font-weight: 300px;
			background-color: $color_shark_approx !important;
			&:hover {
				text-decoration: none;
				border_bottom: none;
				background-color: $color_shark_approx !important;
			}
		}
		.date-nav-wrapper {
			display: block!important;
			margin: 0;
			.date-nav {
				min-height: 10px;
				margin: 0;
				padding-bottom: 0;
			}
		}
		.view-content {
			min-height: 105px;
			height: auto!important;
			height: 105px;
			padding-top: 0;
		}
		.mini {
			width: 100%;
			ul {
				margin: 0 15px;
				padding: 0;
				padding: 8px 0;
				border-top: 3px double $color_silver_approx;
				li {
					margin: 0 1.5px 0 3px;
					padding: 0;
					display: inline;
					position: relative;
					padding: 14px 2px 0;
					&:after {
						content: " | ";
						position: absolute;
						top: 0;
						left: 50%;
						color: $white;
					}
					a {
						color: $white;
						font-size: 23px;
						font-weight: bold;
						background-color: $color_shark_approx;
						z-index: 1000;
					}
					div {
						display: inline;
					}
					&.empty {
						display: none;
					}
					&.sat a {
						color: $color_alizarin_crimson_approx;
					}
					&.sun a {
						color: $color_alizarin_crimson_approx;
					}
					&.today a {
						background-color: $color_slate_gray_approx;
					}
				}
			}
		}
	}
}*/
