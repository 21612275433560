//main: pita.scss

body {

	//&.page-node-17729, page cultura
	&.node-type-sezione { //sezione con immagine

		// @include background(none,jpg,no-repeat,center,top,100%, auto);
		background-color:trasparent!important;
		background-position:center top!important;
		background-size:100% auto!important;

		@media (max-width: $grid-float-breakpoint-max) {

			background-position: center 115px!important;

		}

		section {

			h1 {

				&.page-header {

					padding-top:0;

					@media (max-width: 400px) { //480px

						font-size:1.8rem;

					} // $screen-xs-min

				}

			}

			margin-top: 40%;

			@media (max-width: $grid-float-breakpoint-max) {

				margin-top: 35%;

				.page-content {

					// .view-esperienze {

						// .view-content>div:last-child div .node {

							// margin-bottom:0;

						// }

					// }

				}

			}


			// invece di lavorare sull'ID del menu lavoriamo sulla sua posizione nella pagina di sezione
			.page-content {

				.content-base {

					// modificare il js aggiungendo l'ID della categoria
					// #block-menu-menu-shopping-categorie,
					// #block-menu-menu-sport-categorie,
					// #block-menu-menu-enogastronomia-categorie,
					// #block-menu-menu-cultura-categorie
					// #block-menu-menu-natura-categorie

					.block-menu {

						.menu {

							@extend .row;
							@extend .list-unstyled;
							margin-bottom:0;
							@extend .d-flex;
							@extend .flex-wrap;				  
							// @include d-flex;
							// @include flex-wrap;

							.leaf {

								margin:0;
								margin-bottom:$padding-default;
								@include cols(12, 4, 4);
								@extend %leaf;

								&#cat-fortificazioni {

									.image {

										@include background(cat_fortificazioni,jpg,no-repeat,center,top,100%, auto);

									}

								}


								&#cat-musei {

									.image {

										@include background(cat_musei,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-castelli {

									.image {

										@include background(cat_castelli,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-abbazie {

									.image {

										@include background(cat_abbaziechiese,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-biblio {

									.image {

										@include background(cat_biblio,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-teatri {

									.image {

										@include background(cat_teatri,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-impianti {

									.image {

										@include background(cat_impiantisportivi,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-comprensori {

									.image {

										@include background(cat_sci,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-attivita {

									.image {

										@include background(cat_sportariaaperta,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-golf {

									.image {

										@include background(cat_golf,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-ghiaccio {

									.image {

										@include background(cat_ghiaccio,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-ricette {

									.image {

										@include background(cat_ricette,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-ristoranti {

									.image {

										@include background(cat_ristoranti,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-unesco {

									.image {

										@include background(cat_unesco,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-outlet {

									.image {

										@include background(cat_outlet,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-spacci {

									.image {

										@include background(cat_spacci,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-botanica {

									.image {

										@include background(cat_giardini_botanici,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-storiche {

									.image {

										@include background(cat_giardini_dimore,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-urbani {

									.image {

										@include background(cat_parchi_urbani,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-naturali {

									.image {

										@include background(cat_parchi_riserve,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-enoteche {

									.image {

										@include background(cat_enoteche,jpg,no-repeat,center,top,100%, auto);

									}

								}

								//new

								&#cat-alberimonumentali {

									.image {

										@include background(cat_alberi_monumentali,png,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-grotte {

									.image {

										@include background(cat_grotte,png,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-vigneurbane {

									.image {

										@include background(cat_vigne_urbane,png,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-areeprotette {

									.image {

										@include background(cat_aree_protette,png,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-parchifluviali {

									.image {

										@include background(cat_parchi_fluviali,png,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-prodottialimentari {

									.image {

										@include background(cat_prodotti_alimentari,png,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-localistoricigolosi {

									.image {

										@include background(cat_locali_storici_golosi,jpg,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-libreriestoriche {

									.image {

										@include background(cat_librerie_storiche,png,no-repeat,center,top,100%, auto);

									}

								}

								// &#cat-outlet {

								// 	.image {

								// 		@include background(cat_outlet,png,no-repeat,center,top,100%, auto);

								// 	}

								// }

								&#cat-artigianato {

									.image {

										@include background(cat_artigianato,png,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-fieremercati {

									.image {

										@include background(cat_fiere_mercati,png,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-tradizioni {

									.image {

										@include background(cat_tradizioni,png,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-parchiacquatici {

									.image {

										@include background(cat_parchi_acquatici,png,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-parchiavventura {

									.image {

										@include background(cat_parchi_avventura,png,no-repeat,center,top,100%, auto);

									}

								}

								&#cat-termespa {

									.image {

										@include background(cat_terme_spa,png,no-repeat,center,top,100%, auto);

									}

								}

								@media (max-width: $grid-float-breakpoint-max) { //$screen-sm-max -1  : 767px

									.image {

										background-color:$grigio-scuro;

									}

									margin-bottom:2rem;

									.last {

											margin-bottom: 2rem;


									}


								} // $grid-float-breakpoint-max

								a {

									background-color:$verde;
									color:$nero;
									text-transform:uppercase;
									font-size:1.25rem;
									display: inline-block;
									width: 100%;
									height: 100%;

									&:hover, &:focus {

										background-color:$grigio-scuro;
										color:$bianco;


									}

									span {

										padding: 0.4rem;
										display:inline-block;

										@media (min-width: $grid-float-breakpoint) {	//$screen-sm-min : 768px
											min-height:4.5rem;
										}

										@media (min-width: $screen-md-min) { //992px
											min-height:3rem;
										}

									}

								}

								.image {

											min-height:193px;

											// MEDIA X AREA IMM SENZA IMM

											@media (min-width: $grid-float-breakpoint) {	//$screen-sm-min : 768px

												min-height:156px;

											} // $grid-float-breakpoint


											@media (min-width: $screen-md-min) { //992px

												min-height:208px;

											} // $screen-md-min

											@media (min-width: $screen-lg-min) { //1200px

												min-height:255px; //188px

											} // $screen-lg-min

											@media (min-width: $screen-xl-min) { //1400px

												min-height:326px;

											} // $screen-xl-min


								}

							}

						}

					}

				}

			} //page-content


		}

		.block-anteprima-esperienze {

			padding:$padding-default 0;

		}

	} //sezione

}
