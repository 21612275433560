//main: pita.scss

body {

	.view-filters {

		.views-exposed-form {

			@extend %box-form;

			.views-exposed-widgets {

				@extend .row;

				.views-exposed-widget {

					// float:none;
					float:left!important;
					@include cols(12, 12, 12);

					&.views-widget-filter-field_indirizzo_locality + .views-widget-filter-field_indirizzo_administrative_area,
					&.views-widget-filter-field_indirizzo_locality + .views-widget-filter-provincia,
					&.views-widget-filter-field_indirizzo_locality {

						@include cols(12, 6, 6);

					}

					@extend %element-form;

					label {

						margin-top:0;
						margin-bottom:0;

					}

					&.views-submit-button {

						width: 100%;
						text-align: center;

						.form-submit {

							@extend %button-search-space;

						}

					}

				}

			}

		}

	}


	// filtri specifici x tipo viste

	.view-eventi.view-display-id-page { // specifico solo per pagina 'eventi'

		.view-filters {

			.views-exposed-form {

				.views-exposed-widgets {

					.views-widget-filter-field_categoria_tid,
					.views-widget-filter-between_date_filter {

						@include cols(12, 6, 6);

					}

				}

			}

		}

	}

	.view-impianti-sportivi.view-display-id-page { // specifico solo per pagina 'impianti sportivi'

		.view-filters {

			.views-exposed-form {

				.views-exposed-widgets {

					.views-widget-filter-combine,
					.views-widget-filter-combine + .views-widget-filter-field_attivita_impianti_sportivi_tid {

						@include cols(12, 6, 6);

					}

				}

			}

		}

		// campo tipo impianto

		.views-field-field-attivita-impianti-sportivi {

			text-transform:lowercase;

		}

	} //view-impianti-sportivi

	.view-attivita.view-display-id-page { // specifico solo per pagina 'sport all'aria aperta'

		.view-filters {

			.views-exposed-form {

				.views-exposed-widgets {

					.views-widget-filter-combine,
					.views-widget-filter-combine + .views-widget-filter-field_fruizione_tid {

						@include cols(12, 6, 6);

					}

				}

			}

		}

		// campo tipo impianto

		.views-field-field-attivita-impianti-sportivi {

			text-transform:lowercase;

		}

	} //view-impianti-sportivi



	// paginazione della vista
	.item-list {

		.pager {

			margin:0;
			padding:0;

			li {

				position: relative;
				padding:0.5rem;
				margin:0;
				display:inline-block;

				&.pager-current {

					font-size:$fz-big;
					font-weight:bold;
					color:$arancione;

					@extend %pager-piper;

				}

				&.pager-item {

					@extend %pager-piper;

					a {

						@extend %pager-link-style;

					}

				}

				&.pager-first,
				&.pager-previous,
				&.pager-next,
				&.pager-last {

					a, span {

						@extend %pager-link-style;
						font-size:1.2rem;
					}

				}

			} //li

		}

	}//item-list

}
