//main: pita.scss

.section-bottom {

  // border:1px solid green;
  @extend %middle-xs;
  @include background(cartina, png, no-repeat, top, center, cover, cover);
  min-height: 900px;

  @media (max-width: $grid-float-breakpoint-max) {
    //$screen-sm-max -1  : 767px

    background-position: top center;

  }

  .section-inevidenza {

    padding: 0;

    .block-anteprima-inevidenza {

      padding: 0;

      .view-in-evidenza {

        .view-content {

          .content { // HA SOLO L'IMMAGINE

            display:none;

          }

        }
        
      }

      @media (max-width: $grid-float-breakpoint-max) {
        //$screen-sm-max -1  : 767px

        .view-in-evidenza {

          .view-content {

            // .content {

            //   display: block;

            // }
  
            >div:last-child .node {

              margin-bottom: $padding-default;
    
            }

          }
          
        }

      }

    }

  }

  .schede-categorie {

    /*x allinearlo agli altri contenitori -- no padding*/
    margin-left: 15px !important;
    margin-right: 15px !important;

    ul.menu {

      @extend .list-inline;
      @extend .row;
      @extend %reset-col-space-row;
      // @include d-flex;
      // @include flex-row-reverse;
      // @include flex-wrap-reverse;

      li {

        margin: 0;
        padding: 0;
        min-height: inherit;
        @include cols(12, 12, 3);
        background-size:cover!important;

        &:first-child {

          @include cols(12, 12, 6);
          padding: 0;
          float: right;

        }

        a {

          @extend %link-bianco;
          width: 100%;
          height: 100%;
          text-transform: uppercase;
          font-size: $fz-txt;
          padding: $padding-default;
          @extend %bottom-xs;
          font-weight: bold;

          &:hover {

            background-color: rgba(0, 0, 0, 0.2);

          }

        }

        &.categoria-cultura {

          @include background(scheda_cultura_q, jpg, no-repeat, center, center);

        }

        &.categoria-natura {

          @include background(scheda_natura_q, jpg, no-repeat, center, center);

        }

        &.categoria-sport {

          @include background(scheda_sport_q, jpg, no-repeat, center, center);

        }

        &.categoria-tempolibero {

          @include background(scheda_tempolibero_q, jpg, no-repeat, center, center);

        }

        &.categoria-enogastronomia {

          @include background(scheda_eno_q, jpg, no-repeat, center, center);

        }

      }

    }

  }

  //schede-categorie

}

//section-bottom


@media (min-width: $screen-xs-min) {
  //480px



}

// $screen-xs-min


@media (max-width: $grid-float-breakpoint-max) {
  //$screen-sm-max -1  : 767px

  .section-bottom {

    .schede-categorie {

      ul.menu {

        li { //150

          min-height: 180px;

          a {

            min-height: 180px;

          }

        }

      }

    }

  }

  //.section-bottom


}

// $grid-float-breakpoint-max


@media (min-width: $grid-float-breakpoint) {
  //$screen-sm-min : 768px

  .section-bottom {

    .schede-categorie {

      ul.menu {

        li {

          min-height: 150px;

          a {

            min-height: 150px;

          }

        }

      }

    }

  }

  //.section-bottom

}

// $grid-float-breakpoint


@media (min-width: $screen-md-min) {
  //992px

  .section-bottom {

    .schede-categorie {

      ul.menu {

        li {

          &:first-child {

            min-height: 400px;

            a {

              min-height: 400px;

            }

          }

          min-height: 200px;

          a {

            min-height: 200px;
            padding: $padding-default 0.5rem;

          }

        }

      }

    }

  }

  //.section-bottom

}

// $screen-md-min


@media (min-width: $screen-lg-min) {
  //1200px

  .section-bottom {

    .schede-categorie {

      ul.menu {

        li {

          &:first-child {

            min-height: 500px;

            a {

              min-height: 500px;

            }

          }

          min-height: 250px;

          a {

            min-height: 250px;
            padding: $padding-default 0.5rem;

          }

        }

      }

    }

  }

  //.section-bottom


}

// $screen-lg-min


@media (min-width: $screen-xl-min) {
  //1400px


}

// $screen-xl-min
