//main: pita.scss

// VARIABILI - PITA

//== Colour

//= colore base del portale
	$brand-primary:	#ae1523 !default;
	// $max-height-brand:	49px;
	// $brand-bg-color:		#000;

	$giallo:			#e8c547; // nella scheda 'nei dintorni'
	$arancione:		#e25a30; // linea sup. in evidenza home
	$grigio-nero:		#1c2126; // sf menu aperto
	$grigio-scuro:	#3e4f5c; // banner
	$blu-puffo:		#aebec5; // sfondini scuri elementi
	$grigio-chiaro:	#eaeeec; // sfondino pagina
	$verde:				#71ca11;
	$bianco:			#fff;
	$nero:				#000;
	$rosso-scuro-acc:	#CB4D20;
	$rosso:				#E55926; // linea sottomenu
	$blu-bordo:			#3d4f5d;

	$blu-pista:			#1468B1;
	$rosso-pista:		#EE1A26;
	$verde-pista:		#008000;


//= altri

	// focus state
	$focus:        #ff8c00;
	$keyFocus: $focus;
	$keyThinFocus: $focus;

	$gray-base:            #30373d!default;
	$gray-lighter:			#e5e5e5;
	$gray-dark:			#30373d;
	$gray-light:			#ccc;


//= GENERATE COLOUR NTH-CHILD

	$colorGrad:	#3d4f5d;
	$colorsGrad:	lighten($colorGrad, 18%),
					lighten($colorGrad, 19%),
					lighten($colorGrad, 20%),
					lighten($colorGrad, 21%),
					lighten($colorGrad, 22%),
					lighten($colorGrad, 23%),
					lighten($colorGrad, 24%),
					lighten($colorGrad, 25%),
					lighten($colorGrad, 26%),
					lighten($colorGrad, 27%),
					lighten($colorGrad, 28%),
					lighten($colorGrad, 29%),
					lighten($colorGrad, 30%),
					lighten($colorGrad, 31%),
					lighten($colorGrad, 32%),
					lighten($colorGrad, 33%),
					lighten($colorGrad, 34%),
					lighten($colorGrad, 35%),
					lighten($colorGrad, 36%),
					lighten($colorGrad, 37%),
					lighten($colorGrad, 38%);

//## Gray and brand colors for use across Bootstrap.
// $gray-base:              #000 !default;
// $gray-darker:            lighten($gray-base, 13.5%) !default; // #222
// $gray-dark:              lighten($gray-base, 20%) !default;   // #333
// $gray:                   lighten($gray-base, 33.5%) !default; // #555
// $gray-light:             lighten($gray-base, 46.7%) !default; // #777
// $gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee


//=== font

	$font-weight-thin: 100;
	$font-weight-light: 300;
	$font-weight-regular: 400;
	$font-weight-bold: 700;
	$font-weight-black: 900;

	$font-weight-base: $font-weight-regular;

	$font-size-base-rem:    1rem !default;

	// per calcolare :  36/20 = valore / il valore default
	$font-size-root: 20px !default; // base sopra i 1200px -- 20px
	$font-size-root-mobile: 92% !default;
	$font-size-root-tablet: 96% !default; // base sopra i 992px
	$font-size-root-xl: 140% !default;


	$font-family-base:      'Josefin Sans', sans-serif !default;
	$font-family-special:   'Merriweather', sans-serif;
	$font-family-icon:		'FontAwesome', sans-serif;

//== title

	$font-size-h1:            $font-size-base-rem * 2.5 !default; 	// ~50px
	$font-size-h2:            $font-size-base-rem * 2 !default; 	// ~40px // home esperienze e inevidenza
	$font-size-h2-search:     $font-size-base-rem * 1.75 !default; 	// ~35px // home eventi
	$font-size-h3:            $font-size-base-rem * 1.5 !default; 	// ~30px // nei dintorni
	$font-size-h4:            $font-size-base-rem * 1.25 !default; 	// ~24px // testo normale
	$font-size-h5:            $font-size-base-rem * 1.1 !default;	// ~22px
	$font-size-h6:            $font-size-base-rem !default;

	$fz-big:			$font-size-h1;
	$fz-medium:			$font-size-h2;
	$fz-small:			$font-size-h3;
	$fz-txt:			$font-size-h4;
	$fz-txt-small:		$font-size-h5;
	$fz-footer:			0.8rem; //18px
	$fz-footer-small:	0.6rem; //16px

	$min-height-base:1em;

//== datapicker

	$fz-picker:	0.6rem; //16px

//== Breadcrumbs

	$breadcrumb-padding-vertical:   8px !default;
	$breadcrumb-padding-horizontal: 0 !default;
	/* Breadcrumb background color*/
	$breadcrumb-bg:                 #fff !default;
	/*  Breadcrumb text color*/
	$breadcrumb-color:              #000 !default;
	/* Text color of current page in the breadcrumb*/
	$breadcrumb-active-color:       #000 !default;
	/*  Textual separator for between breadcrumb elements*/
	$breadcrumb-separator:          "/" !default;


//=== Inverted navbar

	$navbar-inverse-color:                      $bianco;
	$navbar-inverse-bg:                         $gray-dark !default;
	$navbar-inverse-font-size:					1.5rem;
	// $navbar-height:                    			50px !default;

//=== menu

	$bg-menu-border:							$gray-light;
	$bg-menu:									$bianco;
	$bg-menu-open:								$gray-lighter;
	$link-menu-color:	 						$brand-primary;
	$link-menu-liv2-color:	 					$nero;
	$link-menu-active-bg: 						$gray-dark;
	$link-menu-active-liv2-bg: 					$gray-dark;
	$link-menu-active-color: 					$bianco;
	$link-menu-hover-bg: 						$nero;
	$link-menu-hover-color: 					$bianco;


//=== spazi

	$padding-default:							2rem;	//40px
	$padding-default-half:						1rem;

	$space-bottom-page:							3em;	//60px;
	$space-bottom-page-small:					1rem;	//20px;
	$space-bottom-footer:						1.25rem;//25px


	$space-header-top:							50px;
	$space-header-LR:							15px;
	$padding-header-element-top:				16px;
	$padding-header-element-bottom:				20px;

	$space-header-bottom-marg:					50px;
	$space-header-bottom-padd:					40px;
	$space-header-bottom-small-marg:			30px;
	$space-header-bottom-small-padd:			0px;
	$space-header-bottom-small-banner:			100px;
	$space-header-bottom-banner:				360px;

//=== spazi Anteprime

	$padding-preview-element:	0.4rem; //8px

//=== altezze spazio vuoto assenza di immagini

$grid-float-breakpoint-empty:177px!important;
$screen-md-min-empty:154px!important;
$screen-lg-min-empty:188px!important;
$screen-xl-min-empty:244px!important;


//=== posizioni

	$zindex-base:998;

//=== awesome sass

	$fa-font-path:        "../fonts/font-awesome-4.7.0/fonts" !default;
	//$fa-font-path:        "//netdna.bootstrapcdn.com/font-awesome/4.7.0/fonts" !default; // for referencing Bootstrap CDN font files directly
