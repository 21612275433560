/* class test */

/*--------------------------------------------------------------------*/

.d-flex { @extend %flexbox; }
.d-flex-inline { @extend %inline-flex; }

/*--------------------------------------------------------------------*/

.flex-direction-A { @include flex-direction; }
.flex-dir-A { @include flex-dir; }

/* row */
.flex-direction-row { @include flex-direction(row); }
.flex-dir-B { @include flex-dir(row); }

/* row-reverse */
.flex-direction-row-reverse { @include flex-direction(row-reverse); }
.flex-dir-C { @include flex-dir(row-reverse); }

/* column */
.flex-direction-column { @include flex-direction(column); }
.flex-dir-D { @include flex-dir(column); }

/* column-reverse */
.flex-direction-column-reverse { @include flex-direction(column-reverse); }
.flex-dir-E { @include flex-dir(column-reverse); }

/*--------------------------------------------------------------------*/

.flex-wrap-A { @include flex-wrap; }

/* nowrap */
.flex-wrap-nowrap { @include flex-wrap(nowrap); }

/* nowrap */
.flex-wrap { @include flex-wrap(wrap); }

/* nowrap */
.flex-wrap-reverse { @include flex-wrap(wrap-reverse); }

/*--------------------------------------------------------------------*/

.flex-flow-A { @include flex-flow; }

/* row nowrap */
.flex-flow-B { @include flex-flow(row nowrap); }

/* column-reverse wrap */
.flex-flow-C { @include flex-flow(column-reverse wrap); }

/*--------------------------------------------------------------------*/

.order-A { @include order; }

/* -1 */
.order-B { @include order(-1); }

/* 5 */
.order-C { @include order(5); }

/*--------------------------------------------------------------------*/

.flex-grow-A { @include flex-grow; }

/* 3 */
.flex-grow-B { @include flex-grow(3); }

/*--------------------------------------------------------------------*/

.flex-shrink-A { @include flex-shrink; }

/* 2 */
.flex-shrink-B { @include flex-shrink(2); }

/*--------------------------------------------------------------------*/

.flex-basis-A { @include flex-basis; }

.flex-basis-B { @include flex-basis(18rem); }

/*--------------------------------------------------------------------*/

.flex-A { @include flex; }

/* 1, 6, 20% */
.flex-B { @include flex(1, 6, 20%); }

/* 1, 20%, null */
.flex-C { @include flex(1, 20%); }

/* 1, null, null */
.flex-D { @include flex(1); }

/*--------------------------------------------------------------------*/

.justify-content-A { @include justify-content; }
.flex-just-A { @include flex-just; }

/* flex-start */
.justify-content-start { @include justify-content(flex-start); }
.flex-just-B { @include flex-just(flex-start); }

/* flex-end */
.justify-content-end { @include justify-content(flex-end); }
.flex-just-C { @include flex-just(flex-end); }

/* flex-end */
.justify-content-center { @include justify-content(center); }
.flex-just-D { @include flex-just(center); }

/* space-between */
.justify-content-beetween { @include justify-content(space-between); }
.flex-just-E { @include flex-just(space-between); }

/* space-around */
.justify-content-around { @include justify-content(space-around); }
.flex-just-E { @include flex-just(space-around); }

/*--------------------------------------------------------------------*/

.align-items-A { @include align-items; }

/* flex-start */
.align-items-start { @include align-items(flex-start); }

/* flex-end */
.align-items-end { @include align-items(flex-end); }

/* center */
.align-items-center { @include align-items(center); }

/* baseline */
.align-items-baseline { @include align-items(baseline); }

/* stretch */
.align-items-stretch { @include align-items(stretch); }

/*--------------------------------*/

.align-self-A { @include align-self; }

/* auto */
.align-self-auto { @include align-self(auto); }

/* flex-start */
.align-self-start { @include align-self(flex-start); }

/* flex-end */
.align-self-end { @include align-self(flex-end); }

/* center */
.align-self-center { @include align-self(center); }

/* baseline */
.align-self-baseline { @include align-self(baseline); }

/* stretch */
.align-self-stretch { @include align-self(stretch); }

/*--------------------------------------------------------------------*/

.align-content-A { @include align-content; }

/* flex-start */
.align-content-start { @include align-content(flex-start); }

/* flex-end */
.align-content-end { @include align-content(flex-end); }

/* center */
.align-content-center { @include align-content(center); }

/* space-between */
.align-content-between { @include align-content(space-between); }

/* space-around */
.align-content-around { @include align-content(space-around); }

/* stretch */
.align-content-stretch { @include align-content(stretch); }
