//main: pita.scss

body {

	&.front {

		.subfooter2 {

			background-color: $bianco;

			.link-external {

				padding-top: 3em;
				padding-bottom: 3em;

				&:hover {

					.slogan {

						span {

							text-decoration: underline;

						}

					}

				}

				@media (min-width: $screen-lg-min) {

					padding-left: 120px;
					padding-right: 120px;

				}

				@media (max-width: $screen-md-min) {

					.content {

						text-align: center;

						img {

							height:50px;

						}

						.logo1 {

							margin: 20px 0;

						}


					}

				}

				a {

					position: absolute;
					display: inline-block;
					// border: 1px solid red;
					top:0;
					left:0;
					width:100%;
					height:100%;

					span {

						@extend .sr-only;

					}

				}

				.content {

					@extend .row;
					@include d-flex;
					@include align-items-center;
					@include flex-wrap;

					.slogan {


					}

					.logo1 {

						text-align: center;

					}

					.logo2 {

						img {

							max-width: 100%;

						}

					}

				}

			}

		}

	}

}
