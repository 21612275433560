//main: pita.scss

// GENERIC DEFINITION - PITA

html {

  font-size: $font-size-root;

  @media (min-width: $screen-xl-min) {
    //1400px

    font-size: $font-size-root-xl;

  }

  // $screen-xl-min

  @media (max-width: $screen-md-max) {
    //$screen-sm-min -1 : 991px

    font-size: $font-size-root-tablet;

  }

  @media (max-width: $grid-float-breakpoint-max) {
    //$screen-sm-min -1 : 767px

    font-size: $font-size-root-mobile;

  }

}

// html

body {

  // cookie BAR

  .ccm_learn_more a {text-decoration: underline;}
  #cconsent-modal .ccm__footer button {background-color: $grigio-scuro;}
  #cconsent-modal .ccm__footer button:hover {background-color: $rosso;}

  // skip link
  // -----------------------------------------------------------------------------

  #skip-link {

    .element-focusable {
      top:0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      z-index: 50;
      background: #495057;
      font-size: 16px;
      line-height: 1.5;
      padding: 1px 1rem 2px;
      border-radius: 0 0 .3rem .3rem;
      border-bottom-width: 0;
      outline: 0;
  
      //visually-hidden
      position: absolute !important;
      overflow: hidden;
      clip: rect(1px,1px,1px,1px);
      width: 1px;
      height: 1px;
      word-wrap: normal;
  
      //focusable 
      &:focus {
          overflow: visible;
          clip: auto;
          width: auto;
          height: auto;
          position: absolute !important;
          color: #fff;
          z-index: 999999;
          outline: 0px auto -webkit-focus-ring-color;
          outline-offset: 0px;
      }  

    }

  }

  // start accessibility focus
  // ----------------------------

  :focus {
    @extend %focus-none;
  }

  .keyFocus {
    @extend %focus-visible;
  }

  .keyFocusNone {
    @extend %focus-none;
  }

  .keyFocusIn {
    @extend %focus-visible;
  }

  .form-control:focus {

    border: 1px solid $keyThinFocus;

  }

  .btn:focus, body input:focus[type="submit"], body .more-link a:focus, .btn.focus, body input.focus[type="submit"], body .more-link a.focus, .btn:active:focus, body input:active:focus[type="submit"], body .more-link a:active:focus, .btn:active.focus, body input:active.focus[type="submit"], body .more-link a:active.focus, .btn.active:focus, body input.active:focus[type="submit"], body .more-link a.active:focus, .btn.active.focus, body input.active.focus[type="submit"], body .more-link a.active.focus {
    outline-offset: 0;
    @extend %focus-visible;
  }

  // end accessibility focus

  @include tipografia;
  background-color: $grigio-chiaro !important;

  &.admin-menu {

    legend {
      border-bottom: 0;
    }

  }

  header {

    @extend %transition-classic;

    @media (min-width: $grid-float-breakpoint) {
      //$screen-sm-min : 768px

      &.navbar-default {

        position: absolute;
        width: 100%;
        top: 0px;
        z-index: $zindex-base;

      }

    }

    .icon-search {

      a {

        color: #fff;
  
        &:hover {
          color:$rosso;
          text-decoration: none;
        }

        &:focus {
          text-decoration: none;
        }
  
      }  

    }

  }

  .breadcrumb {

    background-color: transparent;
    border: 0;
    text-transform: lowercase;
    font-size: 0.9rem;

    span {

      a {

        @extend %link-nero;

      }

      // &.first {

      // display:none;

      // }

      &.delimiter {

        display: none;

      }

      &.last {

        &:after {
          display: none
        }

      }

      // &:before {

      // @include tipografia( "icon", $font-size-base, $nero, $font-weight-regular);
      // content:"\f105";
      // padding: 0 7px 0 3px;
      // line-height: 1.1rem;

      // }

      &:after {

        @include tipografia("icon", $font-size-base, $nero, $font-weight-regular);
        content: ">";
        padding: 0 3px 0 7px;
        line-height: 1.1rem;

      }

    }


  }

  section {

    @media (max-width: $grid-float-breakpoint-max) {
      //$screen-sm-max -1  : 767px

      margin-top: $space-header-bottom-small-marg+$space-header-bottom-small-padd;

    }

    // $grid-float-breakpoint-max


    @media (min-width: $grid-float-breakpoint) {
      //$screen-sm-min : 768px

      margin-top: $space-header-bottom-marg+$space-header-bottom-padd+40px;

    }

  }

  &.node-type-ricette {

    .page-content {

      padding-bottom: 0;

    }

  }

  &.not-front {

    section {

      background-color: $grigio-chiaro;

    }

    // mettiamo spazio sotto ai vari blocchi nella pagina

    div[class^="block-anteprima-"] {

      margin-bottom: $padding-default;

    }

  }

  &.page-search {

    .icon-search {
      display: none;
    }

  }

  .contextual-links-region {

    .messages {

      ul {
        text-align: left
      }

      //@extend .container;
      margin-right: auto;
      margin-left: auto;

    }


  }

  .messages {

    border-width: 2px;
    border-style: solid;
    text-align: center;
    padding: 1rem;
    background-color: $grigio-chiaro;
    background-image: none;


    .error {

      border-color: $rosso;

    }

  }

  #back-to-top {

    bottom: -10000000px;

  }

  .form-control::-moz-placeholder,
  select::-moz-placeholder,
  input[type="text"]::-moz-placeholder {
    color: $nero;
    opacity: 0.5;
    line-height: 1rem;
  }

  select,
  input[type="text"] {

    @extend .form-control;
    border-radius: 0;
    color: $nero;
    height: 2rem;
    padding: 0.5rem;
    font-size: 1rem;

  }

  input[type="submit"] {

    @extend .btn;
    @extend .btn-default;
    font-size: 1rem;
    border-radius: 0;
    padding: 0.5rem;
    height: 2rem;
    line-height: 0;

  }

  .field-type-image .field-item:not(:first-child) {

    display: none;

  }

  .more-link {
    text-align: center;

    a {

      @extend .btn;
      text-transform: uppercase;
      border-radius: 0;
      color: $nero;
      border-color: $nero;
      font-size: $fz-footer;
      padding: $padding-default/2;
      margin-bottom: $padding-default;

      &:hover {

        border: 1px solid $arancione;

      }

    }

  }

  .element-invisible {

    @extend %sr-only;

  }

  .page-header {

    text-transform: uppercase;
    padding: 0;
    border-bottom: 0;

  }

  .node-article,
  .page-content {

    padding-bottom: $space-bottom-page;

    .field-item {

      // img {

      // width: 100%;
      // height: auto;

      // }

      .file {

        img {
          width: auto;
        }

      }

    }

  }

  .node-article {

    .field-name-field-image {

      text-align: center;

      &.field-type-image {

        @media (max-width: $grid-float-breakpoint-max) {
          //$screen-sm-max -1  : 767px

          img {
            width: 100%;
            height: 100%;
          }

        }

        // $grid-float-breakpoint-max

      }

    }

  }

  .page-content {

    @media (min-width: $screen-md-min) {
      //992px

      min-height: 250px;

    }

    &.page-nodo {

      .field-collection-container {

        border: 0;

      }

      .field-name-field-immagine {

        img {
          width: auto;
        }

      }

      .field-name-field-indirizzo {

        margin-bottom: 0.5rem;

      }

      .field-name-field-indirizzo,
      .field-name-field-indirizzo-arrivo,
      .field-name-field-sito-web,
      .field-name-field-email,
      .field-name-field-social,
      .field-name-field-qualifica-struttura {

        .field-label {
          @extend .sr-only;
        }

      }


      // .field-name-field-sito-web .field-item {

      // position:relative;

      // &:before {

      // @include tipografia( "icon", $font-size-base, $nero, $font-weight-regular);
      // content:"\f26b";
      // position: absolute;
      // left: -0.9rem;
      // line-height: 1.1rem;

      // }

      // }

      .field-label-inline {
        // tolti i : da template e messo specifico qui

        .field-label {

          &:after {

            content: ":";
            margin-right: 6px;

          }

        }

      }

      #block-fieldblock-node-ricette-default-field-ingredienti {

        h2 {

          margin-top: 0;
          font-size: 1.5rem;

        }

        .field-item {

          margin-bottom: 0;

        }

        .field-collection-view {

          position: relative;

          &:before {

            @include tipografia("icon", $font-size-base, $nero, $font-weight-regular);
            content: "\f00c";
            position: absolute;
            left: -1.4rem;
            line-height: 1.1rem;

          }

          border:0;
          padding:0;
          /* 0.5em 0 0.5em 0;*/
          margin: 0 1em 0 1.4em;

          .entity-field-collection-item {

            .content {

              .field:first-child {

                float: left;
                margin-right: 10px;
                margin-left: 10px;

                @media (min-width: $grid-float-breakpoint) {

                  margin-left: 0;

                }

              }

            }

          }

        }

      }

      //field-collection-container

      .descrizione {

        margin-bottom: $padding-default;

      }

      .group-indirizzo-im {

        .field-name-field-image {

          @extend %fit-image;

          a {

            &.colorbox {

              &:focus {

                display: inline-block;
                width: 100%;
                height: 100%;
                padding: 3px;

              }

            }

          }

        }

        @media (min-width: $grid-float-breakpoint) {

          background: $blu-puffo;
          /* Old browsers */
          background: -moz-linear-gradient(left, $blu-puffo 0%, $blu-puffo 70%, $grigio-chiaro 70%, $grigio-chiaro 99%);
          /* FF3.6-15 */
          background: -webkit-linear-gradient(left, $blu-puffo 0%, $blu-puffo 70%, $grigio-chiaro 70%, $grigio-chiaro 99%);
          /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right, $blu-puffo 0%, $blu-puffo 70%, $grigio-chiaro 70%, $grigio-chiaro 99%);
          /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$blu-puffo', endColorstr='$grigio-chiaro', GradientType=1);
          /* IE6-9 */

        }

      }

      .group-indirizzo-no-im {

        background: $blu-puffo;

      }

      .group-indirizzo-im,
      .group-indirizzo-no-im {

        .row {

          @extend %row-eq-height;

        }

        .indirizzo {

          word-break: break-all;
          background-color: $blu-puffo;

          padding-top: $padding-default;
          padding-bottom: $padding-default;

          a {

            @extend %link-nero;

          }

        }

        .immagine {

          background-color: $grigio-chiaro;

          @media (max-width: $grid-float-breakpoint-max) {
            //$screen-sm-max -1  : 767px

            // padding-top:$padding-default;
            // padding-bottom:$padding-default;
            padding: 0;

          }

          // $grid-float-breakpoint-max

          @media (min-width: $grid-float-breakpoint) {

            padding-left: 0;

          }

          // $grid-float-breakpoint

          .region-nodo-im {
            position: relative;
          }

          .num-foto {

            position: absolute;
            right: 4rem;
            bottom: 3.5rem;

            &:after {

              @extend .fa;
              position: absolute;
              border: 3px solid #fff;
              content: " ";
              left: -8px;
              top: 8px;
              height: 2rem;
              min-width: 2rem;
              z-index: 1;
              line-height: 2rem;

            }

            span {

              display: inline-block;
              background-color: #fff;
              color: #000;
              padding: 2px 10px;
              font-size: 1rem;
              font-weight: 700;
              z-index: 2;
              position: absolute;
              top: 0;
              height: 2rem;
              min-width: 2rem;
              line-height: 2rem;
              text-align: center;

            }

          }

        }

      }

      .group-info {


        @media (min-width: $grid-float-breakpoint) {

          background: $bianco;
          /* Old browsers */
          background: -moz-linear-gradient(left, $bianco 0%, $bianco 50%, $grigio-chiaro 50%, $grigio-chiaro 99%);
          /* FF3.6-15 */
          background: -webkit-linear-gradient(left, $bianco 0%, $bianco 50%, $grigio-chiaro 50%, $grigio-chiaro 99%);
          /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right, $bianco 0%, $bianco 50%, $grigio-chiaro 50%, $grigio-chiaro 99%);
          /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bianco', endColorstr='$grigio-chiaro', GradientType=1);
          /* IE6-9 */

        }

      }

      .group-info-only-1 {

        background: $bianco;

      }

      .group-info,
      .group-info-only-1 {

        .info-1,
        .info-2 {

          .field {
            margin-bottom: $padding-default/2;
          }

          table {

            .field {

              margin: 0;
              margin-top: 0.5rem;

            }

          }

          padding-top:$padding-default;
          padding-bottom:$padding-default;

          .block-node-fields-block:first-child {

            h2 {
              margin-top: 0;
            }

          }

        }

        .info-1 {

          @media (max-width: $grid-float-breakpoint-max) {
            //$screen-sm-max -1  : 767px

            background-color: $bianco;

          }

          // $grid-float-breakpoint-max


        }

        .info-2 {

          @media (min-width: $grid-float-breakpoint) {

            padding-left: $padding-default;

          }

        }



      }

      .group-mappa {

        @media (min-width: $grid-float-breakpoint) {

          background: $grigio-chiaro;
          /* Old browsers */
          background: -moz-linear-gradient(left, $grigio-chiaro 0%, $grigio-chiaro 50%, $blu-puffo 50%, $blu-puffo 99%);
          /* FF3.6-15 */
          background: -webkit-linear-gradient(left, $grigio-chiaro 0%, $grigio-chiaro 50%, $blu-puffo 50%, $blu-puffo 99%);
          /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right, $grigio-chiaro 0%, $grigio-chiaro 50%, $blu-puffo 50%, $blu-puffo 99%);
          /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$grigio-chiaro', endColorstr='$blu-puffo', GradientType=1);
          /* IE6-9 */

          .region-nodo-mappa {

            min-height: 400px;

          }

        }

        .region-nodo-mappa {

          @include background(texture_trasparente, png, repeat, center, top, auto, auto);
          @include alpha-attribute('background-color', rgba($grigio-chiaro, 1), $bianco);

        }

        .link-muoversinpiemonte {

          background-color: $blu-puffo;
          z-index: $zindex-base;
          padding: 0 $padding-default-half/2;
          text-transform: lowercase;
          bottom: 0;
          position: absolute;

          a {

            @extend %link-nero;

          }

        }

        .legenda-mappa {

          padding-top: $padding-default;
          padding-bottom: $padding-default;

          @media (max-width: $grid-float-breakpoint-max) {
            //$screen-sm-max -1  : 767px

            background-color: $blu-puffo;

          }

          //grid-float-breakpoint-max


          @extend %style-legenda-mappa;


        }

        //legenda-mappa

      }

    }

    //page-nodo

    .search-form {

      @extend %box-form;
      @extend %element-form;

      .container-inline {
        //form-wrapper

        overflow: hidden;

        .form-submit {

          float: right;
          @extend %button-search-space;

        }

      }

    }

  }


  // * su tre notizie la prima large full * //
  .front .block-anteprima-esperienze,
  .block-anteprima-esperienze,
  // .front .block-anteprima-eventi,
  .block-anteprima-eventi {

    @media (min-width: $grid-float-breakpoint) and (max-width: $screen-sm-max) {

      .view-content {

        >div:first-child {

          width: 100%;

        }

      }

      //view-content

    }

  }

  // * spazio in assenza di immagine sulle anteprima * //
  .front .block-anteprima-esperienze,
  .block-anteprima-esperienze {

    .view-content {

      >div {

        .node {

          &.node-teaser {

            @media (min-width: $grid-float-breakpoint) {
              //$screen-sm-min : 768px

              padding-bottom: $grid-float-breakpoint-empty;

            }

            // $grid-float-breakpoint

            @media (min-width: $screen-md-min) {
              //992px

              padding-bottom: $screen-md-min-empty;

            }

            // $screen-md-min

            @media (min-width: $screen-lg-min) {
              //1200px

              padding-bottom: $screen-lg-min-empty; // corretto

            }

            // $screen-lg-min

            @media (min-width: $screen-xl-min) {
              //1400px

              padding-bottom: $screen-xl-min-empty;

            }

            // $screen-xl-min

          }

        }

        //node

      }

      //div

    }

    //view-content

  }

  //block-anteprima-eventi

  .front .block-anteprima-eventi,
  .block-anteprima-eventi {

    .view-eventi {

      .view-content {

        >div {
          // direttamente qui i campi se vista Campi

          &.single-item {
            // aggiunto nella vista di tipo campo su elenco non formattato

            .views-field-field-image {

              @media (min-width: $grid-float-breakpoint) {
                //$screen-sm-min : 768px

                min-height: 177px;

              }

              // $grid-float-breakpoint

              @media (min-width: $screen-md-min) {
                //992px

                min-height: 150px;

              }

              // $screen-md-min

              @media (min-width: $screen-lg-min) {
                //1200px

                min-height: 187px;

              }

              // $screen-lg-min

              @media (min-width: $screen-xl-min) {
                //1400px

                min-height: 242px;

              }

              // $screen-xl-min

            }

          }

        }

      }

    }

    .view-content {

      >div {

        .node {

          &.node-teaser {

            @media (min-width: $grid-float-breakpoint) {
              //$screen-sm-min : 768px

              padding-top: $grid-float-breakpoint-empty;

            }

            // $grid-float-breakpoint

            @media (min-width: $screen-md-min) {
              //992px

              padding-top: $screen-md-min-empty;

            }

            // $screen-md-min

            @media (min-width: $screen-lg-min) {
              //1200px

              padding-top: $screen-lg-min-empty; // corretto

            }

            // $screen-lg-min

            @media (min-width: $screen-xl-min) {
              //1400px

              padding-top: $screen-xl-min-empty;

            }

            // $screen-xl-min

          }

        }

        //node

      }

      //div

    }

    //view-content

  }

  //block-anteprima-eventi

  .region-nodo-correlati,
  .block-anteprima-eventi,
  .block-anteprima-esperienze,
  .block-anteprima-inevidenza {

    h2 {

      text-align: center;
      margin: 0;
      margin-bottom: $padding-default;
      text-transform: uppercase;

    }

    padding:$padding-default;
    padding-bottom:0;
    background-color:$bianco;


  }

  //block-vari

  .block-anteprima-inevidenza {

    padding: 0;

  }

  .region-nodo-correlati,
  .block-anteprima-esperienze,
  .block-anteprima-inevidenza {

    background-color: transparent;

  }

  .block-anteprima-inevidenza {

    padding-left: 0;
    padding-right: 0;

  }


  // sezione correlati (di un contenuti: es. curiosità, ma sono 6)
  .region-nodo-correlati {

    padding: $padding-default 0;

    h3 {

      @extend %space-element-card;
      @extend %style-title-card;

    }

    @extend %link-readmore;


    .content {

      .field-name-field-contenuti-correlati {

        >.field-items {

          @extend .row;

          >.field-item {

            @include cols(12, 6, 4);

            // start GENERATE COLOUR NTH-CHILD
            @for $i from 1 through length($colorsGrad) {
              &:nth-child(#{length($colorsGrad)}n+#{$i}) {

                .node {

                  .content {
                    //dovrebbe essere su: field-name-field-image, ma il div non rimane nel codice

                    background-color: nth($colorsGrad, $i);

                  }

                }

              }
            }

            // end GENERATE COLOUR NTH-CHILD

            .node {

              @extend %style-card;

              .content {
                //dovrebbe essere su: field-name-field-image, ma il div non rimane nel codice

                @extend %style-image-card;

                .field-name-field-image {
                  @extend %caption-image;
                }

                // x le mostre periodo dell'evento
                .field-name-field-data {

                  @extend %space-element-card;
                  @extend %data-anteprima;

                }

              }

              &.node-esperienze {
                // diversa struttura dell'anteprima

                display: flex;
                flex-direction: column-reverse;
                justify-content: flex-end;

                .content {

                  order: 1;

                }

                // TENIAMO SE SERVE UN DOMANI

                //@extend %style-titolo-card-esperienze;

                // h3 {

                // margin-top: 0;
                // margin-bottom: 0;
                // background-color:transparent!important;

                // }

                // position:relative;
                // background-color:$grigio-scuro;

                // &:hover,
                // &:focus {

                // background-color:$nero;

                // }

                // .content {

                // position: absolute;
                // bottom: 0;
                // }


              }

            }

          }

        }

      }

    }

  }

  // override menu responsive
  &.mean-container {

    .slicknav_menu {
      background-color: $nero;
    }

    .mean-bar {

      z-index: 999;
      border-bottom: 1px solid $bianco;

      .mean-nav {

        ul {

          li {

            padding: 0;
            margin: 0;

            a.mean-expand {

              height: 26px;

            }

            span {
              // non è definito nel meanmenu

              display: block;
              float: left;
              width: 90%;
              padding: 1em 5%;
              margin: 0;
              text-align: left;
              color: #fff;
              border-top: 1px solid #383838;
              border-top: 1px solid rgba(255, 255, 255, .5);
              text-decoration: none;
              text-transform: uppercase;

            }

          }

        }

      }

    }

  }

  // end override menu responsive


  // ICONE

  &.page-icone {

    .view-icone {

      .view-content,
      .row-icon,
      .conth-term-taxonomy {
        display: flex;
        flex-flow: row wrap;
      }

      .row-icon,
      .conth-term-taxonomy {

        @extend %icon-width;

      }

      .taxonomy-term,
      .conth-icon {

        @extend %icon-format;

        .field-name-field-icona {

          margin-bottom: 0 !important;

        }


      }

      .conth-term {

        @extend %icon-format-txt;

      }

    }

  }

  &.node-type-ristoranti,
  &.node-type-strutture-ricettive {

    .field-name-field-salute-benessere,
    .field-name-field-sport,
    .field-name-field-dotazioni-camere,
    .field-name-field-dotazioni-comuni,
    .field-name-field-metodi-pagamento,
    .field-name-field-ospitalita,
    .field-name-field-ristorazione,
    .field-name-field-natura,
    .field-name-field-servizi,
    .field-name-field-dotazioni-appartamenti,
    .field-name-field_attivita_struttura,
    .field-name-field-dotazioni-mobilhome,
    .field-name-field-dotazioni-bungalow,
    .field-name-field-dotazioni-caravan,
    .field-name-field-dotazioni-chalet {

      >.field-items {

        display: flex;
        flex-flow: row wrap;

        >.field-item {

          display: flex;
          justify-content: center;
          align-items: flex-start;

          >.field-items {

            .field-item {

              min-height: auto;
              display: inline-block;

              [class^="font-icon-"]:before,
              [class*=" font-icon-"]:before {

                margin-left: 0;

              }

            }

          }

        }

      }

      .conth-term-taxonomy.taxonomy-term {

        @extend %icon-width;

      }

      .taxonomy-term,
      .conth-icon {

        @extend %icon-format;


        .field-name-field-icona {

          margin-bottom: 0 !important;

        }

      }

      .conth-term {

        @extend %icon-format-txt;

      }

    }

  }

  &.node-type-parchi {

    // .page-content.page-nodo {
    //
    // 	.group-info {
    //
    // 		.info-1 {
    //
    .field {

      &.field-name-field-superficie,
      &.field-name-field-quota {

        margin-bottom: 0 !important;

      }

    }

    //
    // 		}
    //
    // 	}
    //
    // }

  }

  // END ICONE

  // impianti di risalita

  .field-name-field-tipologia-impiantirisalita {

    .conth-icon,
    .field-name-field-icona-impianti,
    .conth-term {
      display: inline;
    }

  }

  // difficolta piste sci

  .diff-verde,
  .diff-Rossa,
  .diff-Blu,
  .diff-Nera,
  .diff-non-classificata {

    padding-left: 30px;

    &:before {

    @include tipografia("icon", $font-size-base+4px, $nero, $font-weight-regular);
    content: "\f111";
    position: absolute;
    margin-left: -25px;
    line-height: 1.1rem;

    }

  }

  .diff-non-classificata {

    &:before {

      color:#fff;

    }

  }

  .diff-Rossa {

    &:before {

    color: $rosso-pista;

    }

  }

  .diff-Blu {

    &:before {

    color: $blu-pista;

    }

  }

  .diff-Nera {

    &:before {

    color: $nero;

    }

  }

  .diff-verde {

    &:before {

    color: $verde-pista;

    }

  }

  // NODI A LIVELLO GENERALE

  &.node-type-luoghi {

    .field-name-field-tipologia-luogo {

      margin-bottom: 1rem;

    }


  }

  &.node-type-eventi {

    .field-name-field-categoria {

      margin-top: 1rem;

    }

    .view-periodo {

      margin-bottom: 1rem;

    }



  }

  &.node-type-ristoranti .field-label-above .field-label {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.1;
    margin-top: 40px;
    margin-bottom: 10px;
  }

  &.node-type-ristoranti .field-label-above:first-child .field-label {

    margin-top: 0;

  }

  &.node-type-impianti-sportivi .field-name-field-attivita-impianti-sportivi .field-label,
  &.node-type-impianti-sportivi .field-name-field-spazi-impianti-sportivi .field-label,
  &.node-type-strutture-ricettive .field-type-taxonomy-term-reference .field-label,
  &.node-type-strutture-ricettive .field-name-field-prezzi .field-label,
  &.node-type-strutture-ricettive .field-name-field-supplementi .field-label,
  &.node-type-strutture-ricettive .field-name-field-alta-stagione .field-label,
  &.node-type-strutture-ricettive .field-name-field-bassa-stagione .field-label,
  // &.node-type-musei .field-type-taxonomy-term-reference .field-label,
  // &.node-type-musei .field-name-field-prezzi .field-label,
  // &.node-type-musei .field-name-field-supplementi .field-label,
  //&.node-type-luoghi .field-type-taxonomy-term-reference .field-label,
  //&.node-type-luoghi .field-name-field-prezzi .field-label,
  //&.node-type-luoghi .field-name-field-supplementi .field-label
  &.node-type-alberi-monumentali .field-type-taxonomy-term-reference .field-label,
  &.node-type-alberi-monumentali .field-name-field-prezzi .field-label,
  &.node-type-alberi-monumentali .field-name-field-supplementi .field-label,
  &.node-type-app .field-type-taxonomy-term-reference .field-label,
  &.node-type-app .field-name-field-prezzi .field-label,
  &.node-type-app .field-name-field-supplementi .field-label {

    border-bottom: 1px solid $blu-puffo;
    padding: 5px 0;
    margin-bottom: 0.5rem;

  }

  .field-name-field-disabili .field-item {

    .disabili_si,
    .disabili_no {

      // padding: 3px 10px;
      display: inline-block;
      width: auto;

      &:before {

        color: $bianco;
        font-family: $font-family-icon;
        content: "\f193";
        padding: 6px 8px;
        text-align: center;
        margin-right: 6px;

      }

    }

    .disabili_si {

      &:before {

        background-color: $verde;

      }

    }

    .disabili_no {

      background-color: $rosso;

    }

  }

  // TABELLE COLLECTION
  .field-collection-container {

    .field-items {

      // overflow-x:auto;
      word-break: break-word;

    }

    table {
      width: 100%;
      margin: 0;

      thead th {
        padding: 0.5rem;
        border-bottom: 0;
      }

      tbody {
        border-top: 0;
      }

      tr.odd {
        background-color: #eee
      }

      tr.even {
        background-color: transparent
      }

      tr.odd,
      tr.even {

        border-bottom: 0;

        // border-top:1px solid $grigio-chiaro;
        td {
          padding: 0 0.5rem;
        }

        .field-type-taxonomy-term-reference {
          margin: 0.5rem;
        }

      }

    }

  }

  // SPAZI ATTIVITA' PER IMPIANTI SPORTIVI

  &.node-type-impianti-sportivi {

    #block-fieldblock-node-impianti-sportivi-default-field-indirizzo {

      h2 {

        @extend.sr-only;

      }

    }

    .field-spazi-impianti-sportivi {

      @include cols(12, 12, 12); // x versione mobile

      // pallozzi attività

      > .content {

        > .field-name-field-attivita-impianti-sportivi {

          > .field-label {
            margin-bottom: 30px;
          }

          &.field-type-taxonomy-term-reference {

            > .field-items { // subito sotto Attività

              @extend .row;
              @extend .d-flex;
              @extend .flex-wrap;
              @extend .align-items-baseline;
              // @extend .justify-content-center;

              > .field-item {

                // border:1px solid red;
                @include cols(12, 3, 2);
                @extend .d-flex;
                @extend .align-items-center;
                @extend .justify-content-center;

                .vocabulary-impianti-sportivi-attivita { // tutto ico + txt

                  margin-bottom: 30px!important;

                }

                .field-name-field-icona { // conth-icon

                  > .field-items {

                    @extend .d-flex;
                    @extend .align-items-center;
                    @extend .justify-content-center;

                    > .field-item {

                      width: 110px;
                      height: 110px;
                      border:2px solid $verde;
                      border-radius: 50%;
                      @extend .d-flex;
                      @extend .align-items-center;
                      @extend .justify-content-center;

                      .icon.fontello {

                        font-size: 2.8rem;
                        @extend .d-flex;
                        @extend .align-items-center;
                        @extend .justify-content-center;

                      }


                    }

                  }

                }

                .conth-term {
                  text-align: center;
                }

              }

            }

          }

        }

      }

    }

    // area "spazi"

    .field-collection-container {

      margin-bottom: 0;

      .field-name-field-spazi-impianti-sportivi {

        > .field-label {
          margin-bottom: 30px;
        }

        > .field-items {

          @extend .row;
          @extend .d-flex;
          @extend .flex-wrap;

          > .field-item {

            @include cols(12, 4, 3);
            margin-bottom: 20px;

            > .field-collection-view {

              background-color: $grigio-chiaro;
              height: 100%;

            }

          }

        }

        .field-collection-view {

          @extend .d-flex;
          padding: 0;
          margin: 0;
          border: 0;

          .field-label {

            border: 0;
            width: 100%;

          }

          .field-collection-item-field-spazi-impianti-sportivi {

            .content {

              padding-bottom: 30px;

              .field-label {
                text-transform: uppercase;
                margin-bottom: 0;
              }

              .field-label, .field-items { // su tutti

                @extend .d-flex;
                @extend .align-items-center;
                @extend .justify-content-center;
                @extend .flex-direction-column;
                padding-left: 10px;
                padding-right: 10px;
                text-align: center;

                .field-item {
                  text-align: center;
                }

              }

              .field-name-field-attivita-impianti-sportivi {

                background-color: $blu-bordo;
                color: #fff;
                padding: 25px;
                margin-bottom: 1rem;

                .field-label {

                  @extend .sr-only;

                }

              }

            }

          }

          // administrator action

          .action-links.action-links-field-collection-add { //all ?
            list-style: none;
          }

          ul.field-collection-view-links {
            margin: 0;
            padding: 0;
            padding-left: 1em;
            position: absolute;
            right: 0;
            background-color: #fff;
        }

        }

      }

    }

  }


  &.page-dormire {

    .view-dormire {

      .view-content {

        >div.single-item {

          .views-field {

            &.views-field-field-qualifica-struttura {

              padding-right: 0;

            }

            &.views-field-field-classifcazione-struttura {

              padding-right: 0;
              padding-left: 0;

              .field-content {

                @extend %classificazione-struttura;

              }

            }

          }

        }

      }

    }

  }

  // NODI RICETTIVITA' / DORMIRE
  &.node-type-strutture-ricettive {

    .field-type-list-boolean {
      // se il tipo di campo è boleano ha la classe impostata da preprocess

      &.is-false {
        display: none;
      }

    }

    .field-name-field-certificazioni-struttura {
      // i loghi della certificazione

      padding: 1rem 0;

      >.field-items {

        .field-item {

          display: inline-flex;

          @media (min-width: $screen-md-min) {
            //992px
            margin-right: 0.5rem;
          }

          .conth-term {

            @extend .sr-only;

          }


        }

      }

    }

    .field-qualifica-struttura.field-classifcazione-struttura {

      margin-bottom: 0.5rem;

      //.field-type-taxonomy-term-reference {

      .field-name-field-qualifica-struttura,
      .field-name-field-classifcazione-struttura {
        // metto in linea  ad es. : albergo 3 stelle

        display: inline-flex;

      }

      .field-name-field-classifcazione-struttura {

        .field-items {

          @extend .sr-only;
          
        }

        @extend %classificazione-struttura;


      }

    }


    // .field-name-field-qualifica-struttura.field-type-taxonomy-term-reference {
    // margin-top:0;
    // }

    .field-name-field-capacita-ricettiva,
    .field-name-field-lingue-parlate.field-type-taxonomy-term-reference {

      .field-collection-view {
        border-bottom: 0;
      }

      .field-items {

        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        -webkit-flex-flow: row wrap;
        justify-content: space-around;

        .field-name-field-totale.field-type-number-integer {

          font-size: 2rem;
          font-weight: bold
        }

      }

    }

    .field-collection-container {
      border-bottom: 0;
    }

  }

  // END NODI

  header {

    @extend %transition-classic;

    background-image: linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 117px, $verde 117px, $verde 122px, $bianco 122px, $bianco 124px, $grigio-scuro 0);
    background-image: -o-linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 117px, $verde 117px, $verde 122px, $bianco 122px, $bianco 124px, $grigio-scuro 0);
    background-image: -moz-linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 117px, $verde 117px, $verde 122px, $bianco 122px, $bianco 124px, $grigio-scuro 0);
    background-image: -webkit-linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 117px, $verde 117px, $verde 122px, $bianco 122px, $bianco 124px, $grigio-scuro 0);
    background-image: -ms-linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 117px, $verde 117px, $verde 122px, $bianco 122px, $bianco 124px, $grigio-scuro 0);

    &.search-open {

      background-image: linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 50px, $grigio-scuro 117px, $rosso-scuro-acc 117px, $rosso-scuro-acc 122px, $bianco 122px, $bianco 124px, $grigio-scuro 0);
      background-image: -o-linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 50px, $grigio-scuro 117px, $rosso-scuro-acc 117px, $rosso-scuro-acc 122px, $bianco 122px, $bianco 124px, $grigio-scuro 0);
      background-image: -moz-linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 50px, $grigio-scuro 117px, $rosso-scuro-acc 117px, $rosso-scuro-acc 122px, $bianco 122px, $bianco 124px, $grigio-scuro 0);
      background-image: -webkit-linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 50px, $grigio-scuro 117px, $rosso-scuro-acc 117px, $rosso-scuro-acc 122px, $bianco 122px, $bianco 124px, $grigio-scuro 0);
      background-image: -ms-linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 50px, $grigio-scuro 117px, $rosso-scuro-acc 117px, $rosso-scuro-acc 122px, $bianco 122px, $bianco 124px, $grigio-scuro 0);

    }

    &.navbar-default {

      border: 0;
      background-color: transparent;
      border-radius: 0;
      margin: 0;
      padding: 0;
      min-height: 1rem;

    }

    .navbar-header {

      .logo-container {

        left: 0;
        z-index: $zindex-base;
        position: absolute;

        img {
          width: 80%;
        }

        a {
          margin: 0;
          paddin-top: 4px;
        }

      }

      .navbar-collapse {

        border-top: 0;

      }

      #block-system-main-menu {

        .content {

          >ul {

            >li {

              >ul {

                @extend .dropdown-menu;
                position: relative;
                z-index: 0;
                font-size: 1rem;
                background-color: transparent;
                border: 0;
                border-radius: 0;
                -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.175);
                box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.175);

                >li {

                  >a {
                    // sovrascrivo le definizioni del dropdown

                    white-space: inherit;

                  }

                }

              }

            }

          }

        }

      }

      //#block-system-main-menu

    }

    //navbar-header

    #block-lang-dropdown-language {

      width: 45px !important;
      position: absolute;
      top: 0;
      right: 50px;
      z-index: $zindex-base;


      top: $space-header-top;
      right: $space-header-LR;
      left: auto;
      padding-top: $padding-header-element-top;
      padding-bottom: $padding-header-element-bottom;


      .form-item {
        //override system

        margin: 0;

      }

      .ddsDefault {

        label {
          margin-bottom: 0 !important;
          max-width: 100% !important;
          width: 100% !important;
        }

        .dd-options {

          width: 100% !important;
          padding: 0 !important;
          background: $gray-light;
          box-shadow: 0 0 0 !important;
          border: 0 !important;

          .dd-option {

            cursor: pointer;
            padding: 3px;

            .dd-option-text {

              cursor: pointer;

            }

          }

          .dd-option-selected {

            cursor: default;
            background: $bianco;

            .dd-option-text {

              cursor: default;

            }

          }

        }

        //.dd-options

        .dd-selected {

          border: 1px solid $bianco !important;
          width: 100% !important;
          padding: 0 !important;
          background: $bianco;
          border-radius: 0 !important;
          cursor: pointer;

          label {

            padding-right: 0 !important;
            cursor: pointer;

          }

        }

        //.dd-selected

        .dd-pointer-up,
        .dd-pointer-down {

          right: -8px;
          top: auto;
          bottom: -8px;

          // @extend .#{$fa-css-prefix}-rotate-90;

          -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
          border-width: 8px !important;

          // &:after {

          // @include tipografia( "icon", $font-size-base, $nero, $font-weight-regular);
          // content:"\f0da";

          // }

        }

        .dd-pointer-up {

          border-color: $verde rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) !important;

        }

      }

    }

    //block-lang-dropdown-language


    #block-search-form {

      .icon-search {
        color: $bianco;
        position: absolute;
        right: 100px;
        top: 0;
        z-index: $zindex-base;

        font-size: 1.3rem;
        top: $space-header-top;
        right: 80px;
        left: auto;
        padding-top: $padding-header-element-top;
        padding-bottom: $padding-header-element-bottom;
        cursor: pointer;

        .fa-search:before {
          font-size: 1.2rem;
        }

      }

      //icon-search

      .element-form {

        display: none;

        .style-form {

          overflow: hidden;
          display: inline-flex !important;
          width: 100%;

          @media (min-width: $grid-float-breakpoint) {
            //$screen-sm-min : 768px
            width: 50%;
          }

        }

        .form-item {

          display: inline-block;
          width: 100%;

        }

        .form-item,
        .form-actions {

          margin: 0;
          padding: 50px 0;

          @include tipografia("base", $font-size-base, $nero, $font-weight-regular);

          input[type="text"] {

            border: 2px solid $rosso;

          }

          input[type="submit"] {

            background-color: $rosso;
            color: $bianco;
            border-color: $rosso;
            margin-left: $padding-default-half;

            &:hover,
            &:focus {

              color: $nero;

            }

          }

        }

      }

      //element-form

    }

  }

  //header

  .subfooter {

    background-color: $bianco;

    p {

      @extend .sr-only;

    }

    ul {
      margin: 0;
      padding: 0;
      padding-top: 10px;
      text-align: center;
      @extend .list-inline;

      li {

        margin: 0;
        padding: 0;
        padding-bottom: 10px;
        padding-left: 30px;
        padding-right: 30px;
        width: auto;

        a {

          // border: 1px solid red;
          padding:3px;
          width: 100%;
          height: 100%;
          display: inline-block;

          @media (max-width: $screen-xs-max) {
            //480px

            img {

              width: 100%;
              height: 100%;

            }

          }

        }

      }

    }

  }

  //subfooter

  footer {

    background-color: $grigio-scuro;
    padding: $padding-default 0;
    text-align: center;

    color: $bianco;
    font-size: $fz-footer;

    #credits {

      @include tipografia("special", $fz-footer-small, $bianco, $font-weight-regular);

      p {

        margin-bottom: $space-bottom-footer;

      }

    }

    #block-menu-menu-footer-menu {

      @include tipografia("special", $fz-footer, $bianco, $font-weight-regular);

      ul {

        @extend .list-inline;
        text-align: center;

        li {

          padding: 0;
          margin: 0;

          &:after {

            @include tipografia("icon", $font-size-base, $nero, $font-weight-regular);
            content: "|";
            color: $bianco;
            padding: 0 6px 0 10px;
            line-height: 1.1rem;

          }

          &:last-child {

            &:after {

              display: none;

            }

          }

          a {

            &.active {

              color: $bianco;

              &:hover,
              &:focus {

                color: $bianco;

              }

            }

          }

        }

      }

    }


    a {

      color: $bianco;

      &:hover,
      &:focus {

        color: $bianco;

      }

    }

  }

  @media (max-width: $grid-float-breakpoint-max) {
    //$screen-sm-min -1  : 767px	// da non usare con ( min-width: $screen-xs-min )

    header {

      &.navbar-default {

        min-height: 124px;

      }

      position: inherit;

      .navbar-header {

        .logo-container {

          top: 55px;
          left: 15px;

          img {
            width: 40%;
          }

          a {
            margin: 0;
            paddin-top: 4px;
          }

        }

        .region-header-rightmenu {

          #block-lang-dropdown-language {

            top: 55px;

          }

          #block-search-form {

            top: 100px;

            .icon-search {

              top: 55px;

            }

            .form-item,
            .form-actions {

              padding-top: 120px;

            }

          }

        }

        //region-header-rightmenu

      }

    }

    h1.page-header {

      //margin: $space-header-bottom-small-marg 0 $space-header-bottom-small-marg;
      margin: 0 0 $space-header-bottom-small-marg;
      padding-bottom: 0;

    }

    .breadcrumb {
      margin-bottom: 0;
    }

    h2 {

      margin-top: $padding-default;
      margin-bottom: $padding-default-half;
      padding: 0;

    }


  }

  // $grid-float-breakpoint-max

  @media (max-width: $screen-sx-max) {
    //479px

  }

  @media (min-width: $screen-xs-min) {
    //480px

  }

  @media (min-width: $grid-float-breakpoint) {
    //$screen-sm-min : 768px

    header {

      text-align: center;

      background-image: linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 117px, $verde 117px, $verde 124px, $grigio-scuro 0);
      background-image: -o-linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 117px, $verde 117px, $verde 124px, $grigio-scuro 0);
      background-image: -moz-linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 117px, $verde 117px, $verde 124px, $grigio-scuro 0);
      background-image: -webkit-linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 117px, $verde 117px, $verde 124px, $grigio-scuro 0);
      background-image: -ms-linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 117px, $verde 117px, $verde 124px, $grigio-scuro 0);

      &.search-open {

        background-image: linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 117px, $rosso-scuro-acc 117px, $rosso-scuro-acc 124px, $nero 124px, $grigio-scuro 0);
        background-image: -o-linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 117px, $rosso-scuro-acc 117px, $rosso-scuro-acc 124px, $nero 124px, $grigio-scuro 0);
        background-image: -moz-linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 117px, $rosso-scuro-acc 117px, $rosso-scuro-acc 124px, $nero 124px, $grigio-scuro 0);
        background-image: -webkit-linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 117px, $rosso-scuro-acc 117px, $rosso-scuro-acc 124px, $grigio-scuro 0);
        background-image: -ms-linear-gradient(top, transparent 0px, transparent 50px, $grigio-scuro 50px, $grigio-scuro 117px, $rosso-scuro-acc 117px, $rosso-scuro-acc 124px, $nero 124px, $grigio-scuro 0);

      }

      .navbar-header {
        position: relative;
        float: none;

        // .logo-container {
        // left:0;
        // z-index: $zindex-base;
        // position: absolute;

        // }

        #block-system-main-menu {

          .content {

            position: relative;
            text-align: center;
            padding-top: $space-header-top;

            ul {
              margin: 0;
              padding: 0;
              list-style-type: none;

              &.menu {

                text-align: center;

                li {

                  margin: 0;

                }

                ul {

                  text-align: left;

                }

              }

              // &menu

              li {

                &.leaf {

                  list-style-image: none;
                  list-style-type: none;

                }

                a,
                span {
                  color: $bianco !important;
                }

                a {

                  &:hover,
                  &:focus {

                    text-decoration: none !important;

                  }

                }

                li {

                  a,
                  span {

                    line-height: 1rem;
                    display: inline-block;

                  }

                  a {

                    width: 100%;
                    padding: 0.2rem !important;
                    position: relative;

                    @include underline-link;

                    &:hover,
                    &:focus {

                      background-color: transparent !important;

                    }

                  }

                }

              }

            }

            //ul

            >ul {

              padding-top: $padding-header-element-top;
              line-height: 38px;

              >li {

                display: inline;

                &.expanded {

                  >a,
                  span {

                    &:focus,
                    &:hover {

                      border-color: $rosso-scuro-acc;

                    }

                  }

                }

                >a,
                >span {

                  padding: 0.2rem;
                  padding-top: 0;
                  padding-bottom: $padding-header-element-bottom - 7px;
                  display: inline-block;
                  color: $bianco;
                  text-transform: uppercase;
                  border-bottom: 7px solid $verde;
                  /*5??*/

                  &:focus,
                  &:hover {

                    background-color: $rosso-scuro-acc;

                  }

                }


                >ul {

                  display: none;
                  width: 100%;
                  padding-top: 30px;
                  margin: 0 0 30px 0 !important;
                  padding-left: 150px;
                  padding-right: 50px;

                  >li {

                    width: 30%; // 24%;
                    float: left;
                    display: inline-block;
                    border-right: 1px solid $bianco;
                    min-height: 12rem;
                    padding: 0 20px;

                    &:last-child {
                      border-right: 0;
                    }

                    >a,
                    >span {

                      text-transform: uppercase;

                    }

                    >ul {

                      >li {

                        width: 100%;

                      }

                    }

                  }

                  // > li

                }

                // > ul

              }

              // > li

            }

            // > ul

          }

          // content

          &.open-menu {

            .content {

              >ul {

                >li {

                  &.tab-hover {

                    >a,
                    >span {

                      background-color: $rosso-scuro-acc;

                    }

                  }

                  >a,
                  span {

                    border-color: $rosso-scuro-acc;

                    &:focus,
                    &:hover {

                      >a,
                      span {

                        background-color: $rosso-scuro-acc;

                      }

                    }

                  }

                }

              }

              //ul

            }

            //content

          }

          //open-menu

        }

        //#block-system-main-menu

      }

      //navbar-header

    }

    //header

    .breadcrumb {

      margin: $space-header-bottom-marg 0 0;
      //padding-top: $space-header-bottom-padd/2;
    }

    h1.page-header {

      margin: $space-header-bottom-marg 0 $space-header-bottom-padd;
      //margin: 0 0 $space-header-bottom-padd;
      padding: $space-header-bottom-padd 0 0 0;
      //padding: 0;
      line-height: 2.5rem;

    }

    .breadcrumb+h1.page-header {

      margin: 0 0 $space-header-bottom-padd;
      padding: 0;

    }


  }

  // $grid-float-breakpoint

  @media (min-width: $screen-md-min) {
    //992px

    header {

      .navbar-header {

        #block-system-main-menu {

          .content {

            >ul {

              >li {

                >ul {

                  padding-left: 150px;
                  padding-right: 150px;

                }

              }

            }

          }

        }

      }

    }

  }

  // $screen-md-min

  @media (min-width: $screen-lg-min) {
    //1200px

  }

  // $screen-lg-min

  @media (min-width: $screen-xl-min) {
    //1400px

  }

  // $screen-xl-min

  &.logged-in {


    // .contextual-links-region {

    // .messages {

    // margin:0 15%;
    // margin-bottom: 2rem;

    // }

    // }


    header {

      .region-header-rightmenu {

        #block-search-form {

          .icon-search {

            top: -75px;
            right: 65px;
            z-index: 0;

          }

        }

      }

    }

    &.node-type-sezione {

      section {
        margin-top: 35%;

        .page-content {

          padding-bottom: 0;

        }

      }

    }

    @media (max-width: $grid-float-breakpoint-max) {
      //$screen-sm-min -1  : 767px

      header {

        &.navbar-default {

          min-height: 64px;

        }

        background-image: linear-gradient(top, transparent 0px, transparent 0px, $grigio-scuro 0px, $grigio-scuro 59px, $verde 59px, $verde 64px, $grigio-scuro 0);
        background-image: -o-linear-gradient(top, transparent 0px, transparent 0px, $grigio-scuro 0px, $grigio-scuro 59px, $verde 59px, $verde 64px, $grigio-scuro 0);
        background-image: -moz-linear-gradient(top, transparent 0px, transparent 0px, $grigio-scuro 0px, $grigio-scuro 59px, $verde 59px, $verde 64px, $grigio-scuro 0);
        background-image: -webkit-linear-gradient(top, transparent 0px, transparent 0px, $grigio-scuro 0px, $grigio-scuro 59px, $verde 59px, $verde 64px, $grigio-scuro 0);
        background-image: -ms-linear-gradient(top, transparent 0px, transparent 0px, $grigio-scuro 0px, $grigio-scuro 59px, $verde 59px, $verde 64px, $grigio-scuro 0);

        &.search-open {

          height: 164px;

          background-image: linear-gradient(top, transparent 0px, transparent 0px, $grigio-scuro 0px, $grigio-scuro 59px, $rosso 59px, $rosso 64px, $grigio-scuro 0);
          background-image: -o-linear-gradient(top, transparent 0px, transparent 0px, $grigio-scuro 0px, $grigio-scuro 59px, $rosso 59px, $rosso 64px, $grigio-scuro 0);
          background-image: -moz-linear-gradient(top, transparent 0px, transparent 0px, $grigio-scuro 0px, $grigio-scuro 59px, $rosso 59px, $rosso 64px, $grigio-scuro 0);
          background-image: -webkit-linear-gradient(top, transparent 0px, transparent 0px, $grigio-scuro 0px, $grigio-scuro 59px, $rosso 59px, $rosso 64px, $grigio-scuro 0);
          background-image: -ms-linear-gradient(top, transparent 0px, transparent 0px, $grigio-scuro 0px, $grigio-scuro 59px, $rosso 59px, $rosso 64px, $grigio-scuro 0);

        }

        .navbar-header {

          .logo-container {

            top: 0;

          }

        }

        .region-header-rightmenu {

          top: 0;

          #block-lang-dropdown-language {

            top: 0;

          }

          #block-search-form {

            top: 75px;

            .icon-search {}

            .form-item,
            .form-actions {

              padding: 30px 0;

            }

          }

        }

      }

    }

    //media

    @media (min-width: $grid-float-breakpoint) {
      //$screen-sm-min : 768px

      header {

        .region-navigation {

          .contextual-links-wrapper {
            right: 200px;
            top: 60px;
            z-index: 99999999999999;
          }

        }

        &.search-open {

          height: 600px;

          .region-header-rightmenu {

            #block-search-form {

              &.contextual-links-region {

                position: absolute;
                top: 124px;
                right: 15px;
                width: 100%;

              }


            }

          }

        }

        //&.search-open

      }

    }

    //media

  }

  //.logged-in

  &.page-user {

    .main-container {

      .contextual-links-region {

        .messages {

          margin-bottom: 2rem;

        }

      }

      form#user-login {

        .form-item {

          @media (min-width: $grid-float-breakpoint) {
            //$screen-sm-min : 768px

            width: 50%;

          }

          label {

            display: block;

          }

          input {

            width: 100%;

          }

        }

      }

      ul.primary {

        margin: 0;
        border-bottom: 0px;

        li {

          a {

            padding-top: 6px;
            border-width: 0;

          }

          &.active a {

            padding: 6px;
            border: 0;

            &:hover {

              background-color: $bianco;
              border-bottom: $bianco;

            }

          }

        }

      }

      .content-base {

        padding: 15px;
        margin: 0;
        background-color: $bianco;

        input[type="password"],
        input[type="text"] {

          border: 1px solid $gray-light;
          -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
          padding: 0.5rem;
          height: 2rem;

        }

        #edit-actions {

          input[type="submit"] {
            background-color: $grigio-chiaro;
          }

        }

      }

    }

  }

  //&.page-user


  #colorbox {

    #cboxCurrent {

      color: $grigio-scuro;
      line-height: 0.5rem;

    }

    #cboxPrevious,
    #cboxNext {

      width: 24px;

    }

  }



  // AREA DELLA MAPPA (fuori da )

  .leaflet-control-attribution a {
    
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

  }

  .leaflet-map-pane {

    .leaflet-popup-content {

      h6 {

        font-size: 0.8rem;
        margin-top: 10px;
        margin-bottom: 5px;

        a {

          @extend %link-nero;

        }

        p {
          margin: 0;
        }

      }

      img {
        width: 300px;
        height: 170px;
      }

    }

  }


  // STILE ICON MAPPA

  .awesome-marker {

    .fa {

      height: 100%;

      &:after {

        content: " ";
        background: transparent url("/sites/all/libraries/leaflet/images/marker-shadow.png") no-repeat left top;
        padding: 20px;
        position: absolute;
        bottom: 3px;
        left: 6px;
        z-index: -1;

      }

    }

  }


  // STILE CLUSTER MAPPA

  .clusterMap {
    text-align: center;
    font-size: 0.9rem;
    color: $bianco;

    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;

    border-style: solid;
    border-width: 3px;

    -webkit-box-shadow: 0px 0px 10px 1px rgba(50, 50, 50, 1);
    -moz-box-shadow: 0px 0px 10px 1px rgba(50, 50, 50, 1);
    box-shadow: 0px 0px 10px 1px rgba(50, 50, 50, 1);

  }

  .awesome-marker-cluster .counter {
    display: inline-block;
    width: 100%;
    margin-top: 0.2rem;
    @include tipografia("base", $font-size-h5, $bianco, $font-weight-bold);
  }

  .awesome-marker-cluster i.fa:before {
    font-family: FontAwesome !important;
  }

  $clusterMap-cultura: #F59630;
  $clusterMap-dormire: #D33D29;
  $clusterMap-mangiare: #37A7DA;
  $clusterMap-sport: #71AF26;
  $clusterMap-eventi: #CF51B6;


  .clusterMap.orange {
    @include alpha-attribute('background-color', rgba($clusterMap-cultura, 1), $clusterMap-cultura);
    @include alpha-attribute('border-color', rgba($clusterMap-cultura, .5), $clusterMap-cultura);

  }

  .clusterMap.red {
    @include alpha-attribute('background-color', rgba($clusterMap-dormire, 1), $clusterMap-dormire);
    @include alpha-attribute('border-color', rgba($clusterMap-dormire, .5), $clusterMap-dormire);
  }

  .clusterMap.blue {
    @include alpha-attribute('background-color', rgba($clusterMap-mangiare, 1), $clusterMap-mangiare);
    @include alpha-attribute('border-color', rgba($clusterMap-mangiare, .5), $clusterMap-mangiare);
  }

  .clusterMap.green {
    @include alpha-attribute('background-color', rgba($clusterMap-sport, 1), $clusterMap-sport);
    @include alpha-attribute('border-color', rgba($clusterMap-sport, .5), $clusterMap-sport);
  }

  .clusterMap.purple {
    @include alpha-attribute('background-color', rgba($clusterMap-eventi, 1), $clusterMap-eventi);
    @include alpha-attribute('border-color', rgba($clusterMap-eventi, .5), $clusterMap-eventi);
  }



  // RICERCA DELLA MAPPA

  &.page-itinerario-test {

    .ui-widget {

      font-family: "Josefin Sans", sans-serif !important;

    }

    .ui-menu .ui-menu-item {

      padding-right: 0.5rem;
      padding-left: 0.5rem;

    }

    .ui-autocomplete {

      z-index: 444;

    }

    .ui-widget-container {
      // filtri della mappa

      margin-bottom: 2rem;

      .filtro {
        padding: .5em 1em 0 0;
      }

      label {
        margin-top: 0px;
        margin-bottom: 0px;
      }


      @extend %box-form;

      .ui-widget.ui-widget-content {

        @extend .form-control;
        /*display:initial;
				width:auto;*/

        /*input type text di boostrap???*/
        border-radius: 0;
        color: #000;
        height: 2rem;
        padding: 0.5rem;
        font-size: 1rem;
        /*non lo piglia dentro a element-form*/
        border-color: #7A7A7A;

        /*x allinearli ai pulsanti
				float:left;*/

      }

      .custom-select_itiner_a,
      .custom-select_itiner_b {

        height: 2rem;
        display: inherit;
        background-size: auto 100%;

        input {

          width: 93% !important;
          float: right !important;

        }
      }

      .custom-select_itiner_a {

        background: transparent url("/sites/all/libraries/leaflet/images/marker-icon-high.png") no-repeat left top;

      }

      .custom-select_itiner_b {

        background: transparent url("/sites/all/libraries/leaflet/images/marker-icon.png") no-repeat left top;

      }


      @extend %element-form;


      .submit-button {

        width: 100%;
        text-align: center;

      }

      input[type="submit"] {

        @extend %button-search-space;

        /*@media (min-width: $grid-float-breakpoint) {*/

        margin-right: 1rem;

        /*}*/

      }


    }

    .custom-itinerari {
      // area della mappa

      position: relative;

      .view-header {

        position: absolute;
        right: 0;

        .show_mappa {

          background: $blu-puffo;
          padding-top: $padding-default/2;
          padding-bottom: $padding-default/2;
          padding-left: $padding-default/2;
          padding-right: $padding-default/2;

          @extend %style-legenda-mappa;

        }

      }

    }

    .view-header,
    .leaflet-top,
    .leaflet-bottom {
      // elementi sopra la mappa
      z-index: 444;
    }

  }

  // page-itinerario-test


}

// body


.main {


  [class*="icon-"] {

    &:before {

      font-family: $font-family-icon;
      margin-right: 0.5rem;
      margin-left: 0.2rem;

    }

  }

  .icon-pdf {

    &:before {

      content: "\f1c1";

    }

  }

  .icon-xls {

    &:before {

      content: "\f1c3";

    }

  }

  .icon-word {

    &:before {

      content: "\f1c2";

    }

  }

  .icon-zip {

    &:before {

      content: "\f1c6";

    }

  }

  .icon-pp {

    &:before {

      content: "\f1c4";

    }

  }

  .icon-web {

    &:before {

      content: "\f016";

    }

  }

  .dl-horizontal {

    overflow: hidden;

    dt,
    dd {
      padding: 0.2rem;
    }

    dt {

      white-space: normal;
      position: relative;
      overflow: visible;

      &:first-child {

        &:before {

          border: 0;

        }

      }

      &:before {

        position: absolute;
        width: 1000%;
        border-top: 1px solid $gray-lighter;
        top: 0;
        left: 0;
        content: "";

      }

    }

  }

  //dl-horizontal


}

//main
