// compileCompressed: ../css/$1.css

// override my
@import "variabili.scss";

// font awesome
@import "../fonts/font-awesome-4.7.0/scss/variables";
@import "../fonts/font-awesome-4.7.0/scss/mixins";
@import "../fonts/font-awesome-4.7.0/scss/path";
@import "../fonts/font-awesome-4.7.0/scss/core";
@import "../fonts/font-awesome-4.7.0/scss/larger";
@import "../fonts/font-awesome-4.7.0/scss/fixed-width";
@import "../fonts/font-awesome-4.7.0/scss/list";
@import "../fonts/font-awesome-4.7.0/scss/bordered-pulled";
@import "../fonts/font-awesome-4.7.0/scss/animated";
@import "../fonts/font-awesome-4.7.0/scss/rotated-flipped";
@import "../fonts/font-awesome-4.7.0/scss/stacked";
@import "../fonts/font-awesome-4.7.0/scss/icons";
@import "../fonts/font-awesome-4.7.0/scss/screen-reader";


	/*!
	 * Bootstrap v3.3.7 (http://getbootstrap.com)
	 */

	// Core variables and mixins
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/variables";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/mixins";

	// Reset and dependencies
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/normalize";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/print";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/glyphicons";

	// Core CSS
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/scaffolding";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/type";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/code";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/grid";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/tables";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/forms";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/buttons";

	// Components
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/component-animations";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/dropdowns";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/button-groups";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/input-groups";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/navs";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/navbar";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/breadcrumbs";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/pagination";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/pager";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/labels";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/badges";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/jumbotron";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/thumbnails";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/alerts";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/progress-bars";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/media";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/list-group";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/panels";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/responsive-embed";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/wells";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/close";

	// Components w/ JavaScript
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/modals";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/tooltip";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/popovers";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/carousel";

	// Utility classes
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/utilities";
	@import "../lib/bootstrap3.3.7/stylesheets/bootstrap/responsive-utilities";

@import "mixin-flex.scss";
@import "mixin.scss";
@import "flex.scss";
@import "flex-class.scss";
@import "base.scss";  // versione home nuova
@import "home.scss"; 	// versione home nuova
// @import "../stylesheet_BEFORE_NEW_HOME/base.scss"; // versione home vecchia
// @import "../stylesheet_BEFORE_NEW_HOME/home.scss"; // versione home vecchia
@import "calendar-mini.scss";
@import "data-picker.scss";
@import "comprensori.scss";
@import "sezioni-categorie.scss";
@import "viste.scss";
@import "viste_filtri_paginazione.scss";
@import "section_loghi_subfooter_home.scss";
// @import "section_link_home.scss"; // nuovo ma non più abilitato
@import "categorie_home_square.scss"; // versione home nuova
// @import "categorie_home.scss"; // versione home vecchia
