//main: pita.scss


// eccezione variabili da variabili di boostrap
$screen-sx-max: ($screen-xs-min - 1) !default; // meno di 479px

/* per includere una classe esistente da BOOTSTRAP o FONT-AWESOME

	@extend .dropdown-menu;
	@include cols(12, 4, 4);

*/

//// FONT DEFINE

	//// per richiamarlo @include tipografia( "base", $font-size-base, $nero, $font-weight-regular);
	@mixin tipografia( $family: base, $size:$font-size-base-rem, $color:$nero, $weight:$font-weight-regular) {

		font-size: $size;
		color: $color;
		font-weight: $weight;

		@if $family == base {
			font-family: $font-family-base;
		}

		@elseif $family == special {
			font-family: $font-family-special;
		}

		@elseif $family == icon {
			font-family: $font-family-icon;
		}

	} //tipografia

//// NEW GRID DEFINE

	// XLarge screen / wide desktop
	//** Deprecated `$screen-xl` as of v3.0.1
	$screen-xl:                  1500px !default;
	$screen-xl-min:              $screen-xl !default;
	$screen-xl-desktop:          $screen-xl-min !default;

	// So media queries don't overlap when required, provide a maximum
	$screen-lg-max:              ($screen-xl-min - 1) !default;

	//== Container sizes
	// XLarge screen / wide desktop
	$container-xlarge-desktop:      (1440px + $grid-gutter-width) !default;
	//** For `$screen-xl-min` and up.
	$container-xl:                 $container-xlarge-desktop !default;

	// Container widths
	// Set the container width, and override it for fixed navbars in media queries.
	.container {
	  @media (min-width: $screen-xl-min) {
		width: $container-xl;
	  }
	}

	@mixin make-grid-xlcolumns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}, .col-xl-#{$i}") {
	  @for $i from (1 + 1) through $grid-columns {
		$list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}, .col-xl-#{$i}";
	  }
	  #{$list} {
		position: relative;
		// Prevent columns from collapsing when empty
		min-height: 1px;
		// Inner gutter via padding
		padding-left:  ceil(($grid-gutter-width / 2));
		padding-right: floor(($grid-gutter-width / 2));
	  }
	}

	@include make-grid-xlcolumns;

	@media (min-width: $screen-xl-min) {
	  @include make-grid(xl);
	}

	// Generate the large columns
	@mixin make-xl-column($columns, $gutter: $grid-gutter-width) {
	  position: relative;
	  min-height: 1px;
	  padding-left:  ($gutter / 2);
	  padding-right: ($gutter / 2);

	  @media (min-width: $screen-xl-min) {
		float: left;
		width: percentage(($columns / $grid-columns));
	  }
	}
	@mixin make-xl-column-offset($columns) {
	  @media (min-width: $screen-xl-min) {
		margin-left: percentage(($columns / $grid-columns));
	  }
	}
	@mixin make-xl-column-push($columns) {
	  @media (min-width: $screen-xl-min) {
		left: percentage(($columns / $grid-columns));
	  }
	}
	@mixin make-xl-column-pull($columns) {
	  @media (min-width: $screen-xl-min) {
		right: percentage(($columns / $grid-columns));
	  }
	}


/*
* Shorthand Cols
*
* When using bootstraps grid use the
* shorthand @include cols(xs, sm, md, lg)
* to declare the width of the element.
* Only the required number of media queries
* is needed to be called.
*
* For example:
* @extend .col-xs-12;
* @extend .col-sm-6;
*
* Can be shorthanded to:
* @include cols(12, 6);
*
*/

@mixin cols($xs, $sm: 0, $md: 0, $lg: 0 ) {

    @extend .col-xs-#{$xs};

    @if ($sm != 0) {
      @extend .col-sm-#{$sm};
    }

    @if ($md != 0) {
      @extend .col-md-#{$md};
    }

    @if ($lg != 0) {
      @extend .col-lg-#{$lg};
    }

}


/************************
*
* placeholder
*
************************/

/*

	Basically instead of doing:
	%col-md-4 { ... }

		NUOVA DEFINIZIONE DI PLACEHOLDER (perché possano essere letti dentro a un media)
		@include placeholder('col-md-4') { ... }

		@include placeholder('col-md-4') {
			border-top:3px solid green;
		}

		INCLUDERE NELLA CLASSE
		@include _(col-md-4); // Same as @extend %col-md-4


	PER INCLUDERE QUELLI DEFINITI SOTTO QUI:
	@extend %transition-classic;

*/



%style-legenda-mappa {

	ul {

		@extend .list-unstyled;

		li {

			a {

				line-height: 1.8rem;

				span.map-num {

					background-color:$giallo;
					display: inline-block;
					min-width: 30px;
					padding: 3px 7px;
					font-size: 1rem;
					line-height: 1.2;
					text-align: center;
					white-space: nowrap;
					vertical-align: middle;
					border-radius: 0;
					margin-left: 20px;

				}

				@extend %link-nero;
				display: inline-block;
				width: 100%;
				padding-top: $padding-default-half/2;
				padding-bottom: $padding-default-half/2;

				&:hover,
				&:focus,
				.active {

					background-color:$giallo

				}

				&:before {

				  margin-left: $padding-default-half/2;
					margin-right: $padding-default-half/2;
					@extend .fa;

				}

				&.map-cultura {

					@extend .fa-university;

				}

				&.map-ricettivita {

					@extend .fa-bed;

				}

				&.map-eno {

					@extend .fa-coffee;

				}

				&.map-sport {

					@extend .fa-bicycle;

				}

				&.map-eventi {

					@extend .fa-calendar;

				}

			}

		}

	}

}


%transition-classic {
	-webkit-transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-ms-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}


%sr-only { // riproduco lo style di boostrap
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

%reset-col-space-row {

	margin-left:0!important;
	margin-right:0!important;

}

%reset-col-space-col {

	padding-left:0!important;
	padding-right:0!important;

}

%leaf { // imposto stile  x i Li dei menu

	list-style-image: none;
	list-style-type: none;

}

%classificazione-struttura {

	&:before {

		@include tipografia( "icon", $font-size-base-rem, $font-weight-regular);

	}

	&:after {
		content:"stelle";
		@extend .sr-only;
	}


	&.field_classif_1,
	&.field_classif_1_girasole,
	&.field_classif_1_spiga,
	&.field_classif_1_stella {

		&:before {
			content:"\f006";
		}

	}

	&.field_classif_2,
	&.field_classif_2_girasoli,
	&.field_classif_2_spighe,
	&.field_classif_2_stelle {

		&:before {
			content:"\f006 \f006";
		}

	}

	&.field_classif_3,
	&.field_classif_3_girasoli,
	&.field_classif_3_spighe,
	&.field_classif_3_stelle {

		&:before {
			content:"\f006 \f006 \f006";
		}

	}

	&.field_classif_4,
	&.field_classif_4_girasoli,
	&.field_classif_4_spighe,
	&.field_classif_4_stelle {

		&:before {
			content:"\f006 \f006 \f006 \f006";
		}

	}

	&.field_classif_5,
	&.field_classif_5_girasoli,
	&.field_classif_5_spighe,
	&.field_classif_5_stelle {

		&:before {
			content:"\f006\f006 \f006 \f006 \f006 ";
		}

	}

	&.field_classif_5l,
	&.field_classif_5_stelle_lusso {

		&:before {
			content:"\f006\f006 \f006 \f006 \f006 L";
		}

	}

}

// flex

	%bottom-xs {
		-webkit-box-align: flex-end;
		-ms-flex-align: flex-end;
		align-items: flex-end;
		display: flex;
	}

	%middle-xs {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		display: flex;
	}

		%list {
			display: flex;
			flex-wrap: wrap;
		}

		%list-item {
			display: flex;
			padding: 0.5em;
			width: 33.3%;
		}

		%list-content {
			display: flex;
			flex-direction: column;
			width: 100%;
		}

		%list-content-element {
			flex: 1 0 auto;
		}

// end flex

%link-nero {

	color:$nero;

	&:hover, &:focus {

		color:$nero;
	}

}

%link-no-underline {

	&:hover, &:focus {

		text-decoration:none;
	}

}

%link-bianco {

	color:$bianco;

	&:hover, &:focus {

		color:$bianco;
		text-decoration:none;

	}

}

%pager-piper {

	// &:before,
	// &:after {

		// content:"|";
		// position: absolute;
		// left: 100%;
		// top: -0.2rem;
		// color:$nero;
		// font-size:$fz-big;
		// font-weight:normal;


	// }

	// &:before {

		// left: -0.3rem;
		right:auto;

	// }

}

%pager-link-style {

	font-size:$fz-medium;
	background-color: transparent;
	border: 0;
	border-radius: 0;
	color:$nero;
	padding:0;

	&:hover,
	&:focus {

		color:$arancione;

	}

}

	// BKG DEFINITION
		// @include background(texture,png,no-repeat,center,center,100%, auto);
		@mixin background ( $img:texture, $type:png, $repeat:no-repeat, $x:top, $y:center, $w:100%, $h:auto) {
		    background-image: url(/sites/all/themes/pita/im/#{$img}.#{$type}); //pita -- theme_base_sass
		    background-repeat: #{$repeat};
		    background-position: #{$x} #{$y};
			background-size: #{$w} #{$h};
		}

	//// RGB COLOR FOR ALL ATTRIBUTRE
		// @include alpha-attribute('background-color', rgba($bianco, 0.8), $bianco);
		@mixin alpha-attribute($attribute, $color, $background) {
		  $percent: alpha($color) * 100%;
		  $opaque: opacify($color, 1);
		  $solid-color: mix($opaque, $background, $percent);
		  #{$attribute}: $solid-color;
		  #{$attribute}: $color;
		}

	//// underline link
		// da applicare al tag A
		// @include underline-link ($line-color:$rosso);
		@mixin underline-link ($line-color:$rosso) {

			// color:$line-color;
			position:relative;
			display:inline-block;

			&:before {
			  content: "";
			  position: absolute;
			  width: 100%;
			  height: 1px;
			  bottom: 0;
			  left: 0;
			  background-color: $line-color;
			  visibility: hidden;
			  -webkit-transform: scaleX(0);
			  transform: scaleX(0);
			  -webkit-transition: all 0.3s ease-in-out 0s;
			  transition: all 0.3s ease-in-out 0s;
			}

			&:hover,
			&:focus {

				&:before {
				  visibility: visible;
				  -webkit-transform: scaleX(1);
				  transform: scaleX(1);
				}

			}

		}

%box-form {

	background-color:$blu-puffo;
	padding:$padding-default;
	// margin-bottom:$padding-default;

}

%button-search-align {

	text-align:right;

}

%button-search-space {

	margin-top: 1.6em;

}

%element-form {

	select,
	input[type="text"] {

		border-color:#7A7A7A;

	}

	input[type="submit"] {

		background-color:transparent;
		color:$nero;
		border-color: $nero;

		&:hover,
		&:focus {

			background-color:$bianco;
			color:$nero;
			border-color: $nero;

		}

	}

}

%data-anteprima { // x eventi

	background-color:$arancione;
	color:$bianco;
	font-size:$font-size-h4;
	font-style:italic;
	padding:$padding-preview-element;

}

%row-eq-height {

	@media (min-width: $grid-float-breakpoint) {

	  display: -webkit-box;
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display: flex;

	 }

}

	// ICONE

		%icon-width {

			width:135px;
			text-align:center;
			// border:1px solid blue;

		}

		%icon-format {

			font-size:2.8rem;
			width: 100%;
			margin: 0;
			padding: 0.2rem;
			text-align:center;
			// border:1px solid green;

		}

		%icon-format-txt {

			font-size:0.8rem;
			text-align:center;
			// border:1px solid red;
			width:100%;
		}

	// LINK READMORE
		%link-readmore {

			.links {

				margin:0;
				padding:0;

				.node-readmore {

					margin:0;
					padding:0;

					a {

						position:absolute;
						top:0;
						left:0;
						width:100%;
						height:100%;
						text-indent:-10000000px;
						overflow:hidden;

					}

				}

			}

		}

	// DEFINIZIONI elementi 'card' delle viste o 'correlati' di un contenuto

		%style-titolo-card-esperienze {

			h3 {

				background-color:$grigio-scuro;
				color:$bianco;
				text-transform:uppercase;

				@media (min-width: $grid-float-breakpoint) {	//$screen-sm-min : 768px
					min-height:6.5rem!important;
				}

			}

			&:hover,
			&:focus {

				h3 {

					background-color:$nero;

				}

			}

		}

		%style-card-no-image {

			background-color:transparent;

			&:hover,
			&:focus {

				background-color:transparent;

				.group-field {

					background-color:$blu-bordo;
					color:$bianco;

				}

			}

			.group-field {

				background-color:$bianco;
		    	height: 100%;
				width: 100%;
		    	display: inline-block;
				@extend %style-no-image-card-group;

			} //group-field

		}

		%style-card-eventi {

			height: auto!important; // nonso perché non rimane spaziodel margin con il 100% si attacca sotto

		}


		%style-card {

			position:relative;
			// margin-bottom:$padding-default;
			min-height:10rem;
			height: 100%;

			// @media (min-width: $grid-float-breakpoint) {	//$screen-sm-min : 768px

			// 	min-height:23rem;

			// } // $grid-float-breakpoint

			// @media (min-width: $screen-md-min) { //992px

			// 	min-height:23rem;

			// } // $screen-md-min

			// @media (min-width: $screen-lg-min) { //1200px

			// 	min-height:24rem;

			// } // $screen-lg-min

			// @media (min-width: $screen-xl-min) { //1400px

			// 	min-height:23rem;

			// } // $screen-xl-min

			border-top:6px solid $blu-bordo;
			background-color:$blu-puffo;
			@extend %transition-classic;

			&:hover,
			&:focus {

				border-top:6px solid $arancione;
				background-color:$blu-bordo;
				color:$bianco;

			}

		}

		%style-no-image-card {

			min-height:0;

		}

		%style-no-image-card-group {

			min-height:10rem;

			@media (min-width: $grid-float-breakpoint) {	//$screen-sm-min : 768px

				min-height:12rem;

			} // $grid-float-breakpoint

			@media (min-width: $screen-md-min) { //992px

				min-height:12rem;

			} // $screen-md-min

			@media (min-width: $screen-lg-min) { //1200px

				min-height:13rem;

			} // $screen-lg-min

			@media (min-width: $screen-xl-min) { //1400px

				min-height:12rem;

			} // $screen-xl-min

		}

		%style-no-image-card-homepage-eventi {

			min-height:8rem;

			@media (min-width: $grid-float-breakpoint) {	//$screen-sm-min : 768px

				min-height:10rem;

			} // $grid-float-breakpoint

			@media (min-width: $screen-md-min) { //992px

				min-height:10rem;

			} // $screen-md-min

			@media (min-width: $screen-lg-min) { //1200px

				min-height:11rem;

			} // $screen-lg-min

			@media (min-width: $screen-xl-min) { //1400px

				min-height:10rem;

			} // $screen-xl-min

		}

		%style-image-card { //field-name-field-image / views-field-field-image

			padding:0!important;
			@include background(texture_trasparente,png,repeat,center,top,auto, auto);

			@extend %fit-image;

			// MEDIA X AREA IMM SENZA IMM

			@media (min-width: $grid-float-breakpoint) {	//$screen-sm-min : 768px

				min-height:193px;

			} // $grid-float-breakpoint

			@media (min-width: $screen-md-min) { //992px

				min-height:164px;

			} // $screen-md-min

			@media (min-width: $screen-lg-min) { //1200px

				min-height:202px; //188px

			} // $screen-lg-min

			@media (min-width: $screen-xl-min) { //1400px

				min-height:258px;

			} // $screen-xl-min

		}

		%space-element-card {
			padding: .4rem;
		}

		%style-title-card {
			font-size: 1.25rem;
			line-height: 1.35rem;
			padding-top: 1rem;
			text-transform:uppercase;
		}

		%caption-image {

			position:relative;

			.image-field-caption {

				position: absolute;
				bottom: 0;
				padding: 0.2rem 0.4rem;
				margin: 0;
				font-size: 0.6rem;
				border: 0;
				background-color: $nero;
				color: $bianco;
				right: 0;

			}

		}

		%fit-image {

			img {

				width:100%;
				height:auto;

			}

		}



// BOX SHADOW
// -----------------------------------------------------------------------------

// @include box-shadow-my(0, 0, 10px, 0, rgba(155,162,166, 0.5), true);

@mixin box-shadow-my($top, $left, $blur, $blur-dis, $color, $inset: false) {
	@if $inset {
	  -webkit-box-shadow: inset $top $left $blur $blur-dis $color !important;
	  -moz-box-shadow: inset $top $left $blur $blur-dis $color !important;
	  box-shadow: inset $top $left $blur $blur-dis $color !important;
	}
  
	@else {
	  -webkit-box-shadow: $top $left $blur $blur-dis $color !important;
	  -moz-box-shadow: $top $left $blur $blur-dis $color !important;
	  box-shadow: $top $left $blur $blur-dis $color !important;
	}
  }
  
  %box-shadow-focus-none {
	// usage @extend %box-shadow-focus-none
	@include box-shadow-my(0, 0, 0, 0, $keyFocus, true);
  }
  
  %box-shadow-focus {
	// usage @extend %box-shadow-focus
	@include box-shadow-my(0, 0, 0, 3px, $keyFocus, true);
  }
  
  // focus state
  // -----------------------------------------------------------------------------
  
  %focus-none {
  
	@extend %box-shadow-focus-none;
	outline: auto 0px -webkit-focus-ring-color;
	// border-radius:0!important;
  
  }
  
  %focus-visible {
  
	@extend %box-shadow-focus;
	outline: auto 0px -webkit-focus-ring-color;
	// border-radius:0!important;
  
  }
  
